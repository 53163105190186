<template>
<div>
  <b-popover
      target="popover-reactive-1"
      triggers="click blur"
      :show.sync="popoverShow"
      placement="bottom-start"
      container="my-container"
      ref="popover"
      @show="onShow"
      @shown="onShown"
      @hidden="onHidden"
  >
    <template #title>
      <b-row>
        <b-col><h2 class="exporter-title">{{ $t('admin.notification.export.title') }}</h2></b-col>
        <b-col cols="4"><h2 class="wipe-all" @click="onWipeClick">{{ $t('admin.notification.export.wipe') }}</h2></b-col>
      </b-row>
    </template>
    <template>
      <div v-if="$store.getters.getGlobalExportStatus.length === 0">
        {{$t("admin.notification.export.list.empty")}}
      </div>
      <div class="notification_container">
        <NotificationListItem
            v-for="file in $store.getters.getGlobalExportStatus"
            :key="`${file.fileName}_${Math.random()}`"
            :name="file.fileName"
            :value="parseFloat(file.percentage)"
            :export-file="file.exporter"
            :type="file.type"
        />
      </div>
    </template>
  </b-popover>
</div>
</template>

<script>
import NotificationListItem from "@/components/Notification/NotificationListItem.vue";

export default {
    name: "NotificationContainer",
    components: {NotificationListItem},
    props: {
        popoverShow: {
            type: Boolean,
            required: true,
            default: ()=> false
        },
        onShow: {
            type: Function,
            required: true,
            default: ()=> {}
        },
        onShown: {
            type: Function,
            required: true,
            default: ()=> {}
        },
        onHidden: {
            type: Function,
            required: true,
            default: ()=> {}
        },
        onClose: {
            type: Function,
            required: true,
            default: ()=> {}
        },
        onWipeClick: {
            type: Function,
            required: true,
            default: ()=> {}
        },
    }
};
</script>

<style scoped>
.exporter-title{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  color: #000740;
  padding: 0;
}
.wipe-all {
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: #37ABA1;
  padding: 0;
  cursor: pointer;
}
.notification_container {
  max-height: 17.4rem;
  overflow-x: scroll;
}
</style>
