/* eslint-disable quote-props */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import ApiError from '@/store/models/errors/ApiError';

/**
 * This factory creates an instance of Axios for your project.
 */
export default class AxiosFactory {
    constructor(baseUrl) {
        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        this.https = axios.create({
            baseURL: baseUrl,
            headers: this.headers,
            timeout: 1000 * 60 * 10, // 10 minutes
        });

        this.httpsRetry = axiosRetry(this.https, { retries: 3 });

        /**
         * This interceptor catches all the error responses coming from your Axios API,
         * and transforms the error into an ApiError object if the format of the error matches with the format of ApiError. Should you decide to customize ApiError, you need to adjust the code of this interceptor as well.
         */
        this.https.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    if (!!error.response.status && error.response.status === 404) {
                        return Promise.reject(new ApiError(new Date(), 'API_NOT_FOUND', error.response.data));
                    }
                    if (!!error.response.data && !!error.response.data.timestamp && !!error.response.data.errorCode && !!error.response.data.details) {
                        return Promise.reject(new ApiError(error.response.data.timestamp, error.response.data.errorCode, error.response.data.details));
                    }
                }
                return Promise.reject(error);
            });
    }

    getAxiosInstance() {
        return {
            https: this.https,
            httpsRetry: this.https
        };
    }
}
