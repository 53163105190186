<template>
  <b-row class="general-content">
    <b-col>
      <header><p>{{ $t('admin.settings.content.users.title') }}</p></header>
      <div class="modal-main">
        <BlankLine :number-of-rows="1"/>
        <b-row v-if="!isUserAbleToHandleUsers()">
          <b-col>
            <p style="color: red;">{{ $t('admin.settings.content.common.not_allowed') }}</p>
          </b-col>
        </b-row>
        <div v-if="isLoading" class="user-container text-center">
          <BlankLine :number-of-rows="1"/>
          <b-spinner label="Spinning" type="grow" variant="primary"></b-spinner>
        </div>
        <div v-if="!isLoading && isUserAbleToHandleUsers()" class="user-container">
          <b-row>
            <b-col class="col-3"><label>{{ $t('admin.settings.content.common.picture') }}</label></b-col>
            <b-col class="col-3"><label>{{ $t('admin.settings.content.common.users') }}</label></b-col>
            <b-col class="col-3"><label class="align-container-right">{{
                $t('admin.settings.content.common.role')
              }}</label></b-col>
            <b-col class="col-3"><label class="align-container-right">{{
                $t('admin.settings.content.common.envs')
              }}</label></b-col>
          </b-row>
          <b-row v-for="user in allUsers" :key="user.user_id">
            <b-col class="col-3"><label><img :src="user.thumb" alt="Profile"></label></b-col>
            <b-col class="col-3"><label style="overflow-wrap: anywhere;">{{ user.name }}</label></b-col>
            <b-col id="roles-list-container" class="col-3">
              <RoleContainer :is-roles="true" :roles="user.app_metadata.roles"/>
            </b-col>
            <b-col id="roles-list-container" class="col-3">
              <RoleContainer :is-roles="true" :roles="user.app_metadata.envs"/>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<style>
.user-container {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>;
<script>
import RoleContainer from '@/components/Role/RoleContainer';
import ApiUtils from '@/utils/ApiUtils';
import BlankLine from "@/components/Blank/BlankLine.vue";

export default {
    name: 'UsersSettings',
    components: {
        BlankLine,
        RoleContainer
    },
    mixins: [ApiUtils],
    props: {
        showUserCreator: {
            type: Function,
            default: () => {
            }
        }
    },
    data() {
        return {
            myUserRoles: [],
            allUsers: [],
            isLoading: true,
            isLoadingImage: true,
        };
    },
    async mounted() {
        const appMetadataClaim = 'https://payday.works/app_metadata';
        const userRole = await this.$auth.getUserRoles();
        const appMetadata = userRole.data[appMetadataClaim];
        if (appMetadata?.roles) {
            this.myUserRoles = appMetadata.roles;
        }
        await this.getAllUsers();
    },
    methods: {
        async getAllUsers() {
            await this.setHeader();
            this.isLoading = true;
            try {
                const response = await this.$api.getAuthUsers();
                const data = response.data;
                this.allUsers = data.map(user => {
                    const newUser = user;
                    newUser.thumb = user.picture;
                    return newUser;
                }).map(user => {
                    this.getUserThumbnail(user.user_id, user.picture)
                        .then(result => {
                            /*eslint-disable no-param-reassign*/
                            user.thumb = result;
                            /*eslint-enable no-param-reassign*/
                        });
                    return user;
                });
                this.isLoading = false;
            } catch (e) {
                console.error(e);
                this.isLoading = false;
            }
        },
        getUserThumbnail(userId, defaultPicture) {
            this.isLoadingImage = true;
            return this.setHeader()
                .then(() => {
                    try {
                        return this.$api.getAuthUserImageById(userId)
                            .then(response => {
                                this.isLoadingImage = false;
                                if (response === '') return defaultPicture;
                                return response;
                            });
                    } catch (e) {
                        console.error('Request rejected', e);
                        this.isLoadingImage = false;
                    }
                });
        },
        async getUserImage() {
            try {
                await this.setHeader();
                return await this.$api.getAuthUserImage();
            } catch (e) {
                console.error(e);
            }
        },
        isUserAbleToHandleUsers() {
            return !(this.myUserRoles.includes('ROLE_USER') || this.myUserRoles.includes('ROLE_VIEWER'));
        }
    }
};
</script>
