import colors from "@/utils/ColorsConts";

class PrStatus {
    constructor(symbol, name, className, color) {
        this.symbol = symbol;
        this.name = name;
        this.className = className;
        this.color = color;
    }
}

const PayrollStatus = {
    OPEN: new PrStatus(
        'OPEN',
        'admin.invoice.status.open',
        'payroll-status-open-container',
        colors.lightGrey
    ),
    FINALIZED: new PrStatus(
        'FINALIZED',
        'admin.invoice.status.closed',
        'payroll-status-closed-container',
        colors.lightGreen
    ),
    PENDING: new PrStatus(
        'PENDING',
        'admin.transactions.status-pending',
        'payment-status-toconfirm-container',
        colors.lightBlue
    ),
    REFUSED: new PrStatus(
        'REFUSED',
        'admin.transactions.status-refused',
        'payment-status-failed-container',
        colors.red
    ),
    UNKNOWN: new PrStatus(
        'UNKNOWN',
        'admin.invoice.status.unknown',
        'payroll-status-open-container',
        colors.lightGrey
    )
};

export function getPayrollStatus(status){
    if (PayrollStatus[status] !== undefined) return PayrollStatus[status];
    else return PayrollStatus["UNKNOWN"];
}
export function getAllPayrollStates(){
    return Object.keys(PayrollStatus).map(key => PayrollStatus[key]);
}
export default PayrollStatus;
