class PType {
    constructor(symbol, name) {
        this.symbol = symbol;
        this.name = name;
    }
}

const PaymentType = {
    PIS: new PType("PIS", 'admin.transactions.type-direct'),
    WALOUT: new PType("WALOUT", 'admin.transactions.type-credit'),
    WAL: new PType("WAL", 'admin.transactions.type-credit'),
    WALC: new PType("WALC", 'admin.transactions.type-credit'),
    WALCOUT: new PType("WALCOUT", 'admin.transactions.type-credit'),
    WALCADJ: new PType("WALCADJ", 'admin.transactions.status-net-adj'),
    PAYOUT: new PType("PAYOUT", 'admin.transactions.type-debit-manual'),
    PAYOUTAUTO: new PType("PAYOUTAUTO", 'admin.transactions.type-debit-auto'),
    PAYOUTFEE: new PType("PAYOUTFEE", 'admin.transactions.platform-fee'),
    PAYOUTSET: new PType('PAYOUTSET', 'admin.transactions.payout-set'),
    PAYC_ADJHOUR: new PType('PAYC_ADJHOUR', 'admin.transactions.status-hour-adj'),
    PAYC_ADJNET: new PType('PAYC_ADJNET', 'admin.transactions.type-settlement'),
    PAY_STORED: new PType('PAY_STORED', 'admin.transactions.payout-set'),
    UNKNOWN: new PType('UNKNOWN', 'admin.invoice.status.unknown'),
};

export function getPaymentType(type) {
    if (PaymentType[type] !== undefined) return PaymentType[type];
    else return PaymentType["UNKNOWN"];
}

export function isAnyPayout(type) {
    return type === PaymentType.PAYOUT.symbol ||
        type === PaymentType.PAYOUTAUTO.symbol ||
        type === PaymentType.PAYOUTFEE.symbol ||
        type === PaymentType.PAYOUTSET.symbol;
}

export default PaymentType;
