<template>
  <div :style="{ transform: 'rotate(' + (360) + 'deg)' , backgroundColor: bgColor}"
       class="notification-bell">
    <div class="progress-ring" @click="onClick">
      <div v-if="$store.getters.isFetchingForExport" class="progress-bar infinite"></div>
    </div>
    <ImageContainer
        id="popover-reactive-1"
        :is-clickable="true"
        alt-img="Notification Bell"
        class-name="notification-bell-img"
        img-name="ic-notification-bell.svg"/>
    <b-badge v-if="$store.getters.downloadedExportFiles > 0" class="my-badge active-badge">
      {{ $store.getters.downloadedExportFiles }}
      <span class="sr-only">{{ $t('admin.common.action-required') }}</span>
    </b-badge>
    <NotificationContainer
        :on-close="onClick"
        :on-hidden="onHidden"
        :on-show="onShow"
        :on-shown="onShown"
        :on-wipe-click="onWipeClick"
        :popover-show="popoverShow"
    />
  </div>
</template>

<script>
import ImageContainer from "@/components/Layout/Img/ImageContainer.vue";
import NotificationContainer from "@/components/Notification/NotificationContainer.vue";

export default {
    name: "NotificationBell",
    components: {NotificationContainer, ImageContainer},
    props: {
        isFetching: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            isActive: false,
            rightBorderBound: 50,
            bottomBorderBound: 75,
            leftBorderBound: 95,
            totalBound: 100,
            primaryColor: "#000740",
            secondaryColor: "#F9F9F9",
            inactiveBgColor: "transparent",
            activeBdColor: "rgba(55, 171, 161, 0.1)",
            popoverShow: false
        };
    },
    computed: {
        bgColor() {
            if (this.isActive) {
                return this.activeBdColor;
            } else {
                return this.inactiveBgColor;
            }
        },
        anim() {
            if (this.$store.getters.isFetchingForExport) {
                return "infinite";
            } else {
                return "none";
            }
        },
    },
    methods: {
        onShow() {
            return false;

        },
        onShown() {
            return false;

        },
        onHidden() {
            return false;

        },
        onClick() {
            this.popoverShow = !this.popoverShow;
            this.isActive = !this.isActive;
        },
        onWipeClick() {
            this.$store.commit('wipeAllProgress');
        }
    }
};
</script>

<style scoped>
.notification-bell {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  margin: 4px 0 0;
  border-radius: 25%;
}

.notification-bell > a, .notification-bell > div {
  margin: 0;
  padding: 0;
  border-radius: 10%;
}

.progress-ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  transform-origin: center center;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid v-bind('secondaryColor');
  border-top-color: v-bind('primaryColor');
  animation: progress-animation 1s linear v-bind('anim');
  background-color: transparent;
}

@keyframes progress-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.my-badge {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.5px 5px;
  gap: 10px;
  width: 20px;
  height: 20px;
  background: #D21B02;
  color: #FFFFFF;
  text-align: center;
  border-radius: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.active-badge {
  border: 1px solid #FFFFFF;
}
</style>
