export default {
    getUserData: process.env.VUE_APP_ENDPOINT_GET_USERS,
    getTransactions: process.env.VUE_APP_ENDPOINT_GET_TRANSACTIONS,
    getWalTransactions: process.env.VUE_APP_ENDPOINT_GET_WAL_TRANSACTIONS,
    getPisTransactions: process.env.VUE_APP_ENDPOINT_GET_PIS_TRANSACTIONS,
    getToken: process.env.VUE_APP_ENDPOINT_GET_TOKEN,
    getGigsShift: process.env.VUE_APP_ENDPOINT_GET_GIG_SHIFTS,
    getGigsWallet: process.env.VUE_APP_ENDPOINT_GET_GIG_WALLET,
    getCompanies: process.env.VUE_APP_ENDPOINT_GET_COMPANIES,
    enableUser: process.env.VUE_APP_ENDPOINT_ENABLE_USER,
    deleteUser: process.env.VUE_APP_ENDPOINT_DELETE_USER,
    updateUser: process.env.VUE_APP_ENDPOINT_UPDATE_USER,
    getErrorServer: process.env.VUE_APP_ENDPOINT_GET_SERVER_ERROR,
    getErrorCount: process.env.VUE_APP_ENDPOINT_GET_ERROR_COUNT,
    approvePisPayment: process.env.VUE_APP_ENDPOINT_APPROVE_PIS_PAYMENT,
    refusePisPayment: process.env.VUE_APP_ENDPOINT_REFUSE_PIS_PAYMENT,
    approveWalcPayment: process.env.VUE_APP_ENDPOINT_APPROVE_WALC_PAYMENT,
    refuseWalcPayment: process.env.VUE_APP_ENDPOINT_REFUSE_WALC_PAYMENT,
    getCompanyBalance: process.env.VUE_APP_ENDPOINT_GET_COMPANY_BALANCE,
    getPayrolls: process.env.VUE_APP_ENDPOINT_GET_PAYROLLS,
    getPayrollPeriods: process.env.VUE_APP_ENDPOINT_GET_PAYROLL_PERIODS,
    getBalanceAndDeposit: process.env.VUE_APP_ENDPOINT_GET_BALANCE_DEPOSIT,
    getNotifications: process.env.VUE_APP_ENDPOINT_GET_NOTIFICATIONS,
    getMainNotifications: process.env.VUE_APP_ENDPOINT_GET_MAIN_NOTIFICATIONS,
    getTemplates: process.env.VUE_APP_ENDPOINT_GET_TEMPLATE,
    postNotification: process.env.VUE_APP_ENDPOINT_POST_SCHEDULE_NOTIFICATION,
    putNewDeposit: process.env.VUE_APP_ENDPOINT_PUT_BALANCE_DEPOSIT,
    updateAuthUser: process.env.VUE_APP_ENDPOINT_PUT_UPDATE_AUTH_USER,
    updateAuthUserImage: process.env.VUE_APP_ENDPOINT_PUT_UPDATE_AUTH_USER_IMAGE,
    addAuthUsers: process.env.VUE_APP_ENDPOINT_POST_ADD_AUTH_USER,
    getAuthUsers: process.env.VUE_APP_ENDPOINT_GET_AUTH_USERS,
    getAuthUserRoles: process.env.VUE_APP_ENDPOINT_GET_USER_ROLES,
    getAuthUserImage: process.env.VUE_APP_ENDPOINT_GET_AUTH_USER_IMAGE_BY_ID,
    updateAuthUserPassword: process.env.VUE_APP_ENDPOINT_PUT_UPDATE_USER_PASSWORD,
    getMuserInfo: process.env.VUE_APP_ENDPOINT_GET_M_USER_INFO,
    retryFailedTransactions: process.env.VUE_APP_ENDPOINT_GET_RETRY_FAILED_PAYMENT,
    executePis: process.env.VUE_APP_ENDPOINT_POST_EXEC_PIS,
    getCompaniesData: process.env.VUE_APP_ENDPOINT_GET_COMPANIES_DATA,
    updateCompanyData: process.env.VUE_APP_ENDPOINT_PUT_COMPANIES_DATA,
    confirmWalTransaction: process.env.VUE_APP_ENDPOINT_PUT_CONFIRM_WAL,
    refuseWalTransaction: process.env.VUE_APP_ENDPOINT_PUT_REFUSE_WAL,
    getInvoices: process.env.VUE_APP_ENDPOINT_GET_INVOICESS,
    getInvoiceDetail: process.env.VUE_APP_ENDPOINT_GET_INVOICE,
    putConfirmWalcOut: process.env.VUE_APP_ENDPOINT_PUT_CONFIRM_WALCOUT,
    putRefuseWalcOut: process.env.VUE_APP_ENDPOINT_PUT_REFUSE_WALCOUT,
    putConfirmWalOut: process.env.VUE_APP_ENDPOINT_PUT_CONFIRM_WALOUT,
    putRefuseWalOut: process.env.VUE_APP_ENDPOINT_PUT_REFUSE_WALOUT,
    putUpdateTxPis: process.env.VUE_APP_ENDPOINT_PUT_UPDATE_TX_PIS,
    putUpdateTxWalc: process.env.VUE_APP_ENDPOINT_PUT_UPDATE_TX_WALC,
    putUpdateTxWal: process.env.VUE_APP_ENDPOINT_PUT_UPDATE_TX_WAL,
    postUpdateUserStatus: process.env.VUE_APP_ENDPOINT_POST_UPDATE_USER_STATUS,
    putRegenerateDeposit: process.env.VUE_APP_ENDPOINT_PUT_REGENERATE_DEPOSIT,
    putMakePayrollModifiable: process.env.VUE_APP_ENDPOINT_PUT_MAKE_PAYROLL_MODIFIABLE,
    putConfirmBatchTransactions: process.env.VUE_APP_ENDPOINT_PUT_CONFIRM_BATCH_TRANSACTIONS,
    putRefuseBatchTransactions: process.env.VUE_APP_ENDPOINT_PUT_REFUSE_BATCH_TRANSACTIONS,
    getPisDetails: process.env.VUE_APP_ENDPOINT_GET_PIS_DETAILS,
    getWalcDetails: process.env.VUE_APP_ENDPOINT_GET_WALC_DETAILS,
    getWalcOutDetails: process.env.VUE_APP_ENDPOINT_GET_WALCOUT_DETAILS,
    getWalDetails: process.env.VUE_APP_ENDPOINT_GET_WAL_DETAILS,
    getWalOutDetails: process.env.VUE_APP_ENDPOINT_GET_WALOUT_DETAILS,
    getWorkerInfos: process.env.VUE_APP_ENDPOINT_GET_USER_INFO,
    getWorkersExport: process.env.VUE_APP_ENDPOINT_GET_WORKERS_EXPORT,
    getWorkersPartialExport: process.env.VUE_APP_ENDPOINT_GET_WORKERS_PARTIAL_EXPORT,
    getTransactionsExport: process.env.VUE_APP_ENDPOINT_GET_TRANSACTIONS_EXPORT,
    getTransactionsPartialExport: process.env.VUE_APP_ENDPOINT_GET_TRANSACTIONS_PARTIAL_EXPORT,
    getWalTransactionsExport: process.env.VUE_APP_ENDPOINT_GET_WAL_TRANSACTIONS_EXPORT,
    getWalTransactionsPartialExport: process.env.VUE_APP_ENDPOINT_GET_WAL_TRANSACTIONS_PARTIAL_EXPORT,
    getPisTransactionsExport: process.env.VUE_APP_ENDPOINT_GET_PIS_TRANSACTIONS_EXPORT,
    getPisTransactionsPartialExport: process.env.VUE_APP_ENDPOINT_GET_PIS_TRANSACTIONS_PARTIAL_EXPORT,
    getPayrollsExport: process.env.VUE_APP_ENDPOINT_GET_PAYROLLS_EXPORT,
    getInvoicesExport: process.env.VUE_APP_ENDPOINT_GET_INVOICES_EXPORT,
    getDepositsExport: process.env.VUE_APP_ENDPOINT_GET_DEPOSIT_EXPORT,
    postOnBoardCompany: process.env.VUE_APP_ENDPOINT_POST_ONBOARD_COMPANY
};
