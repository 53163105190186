import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {defaultStoreStatus} from "@/store/defaultStoreStatus";
import "@/utils/StringExtension";
import getters from "@/store/getters";
import mutations from "@/store/mutations";

Vue.use(Vuex);
export const PIS_WALLET_TYPE = 0;
export const WAL_WALLET_TYPE = 1;
export const WALC_WALLET_TYPE = 2;

export default new Vuex.Store({
    plugins: [createPersistedState({storage: window.localStorage})],
    state() {
        return defaultStoreStatus;
    },
    mutations: mutations,
    getters: getters
});

