<template>
  <div class="language-picker">
    <b-dropdown-item v-for="locale in locales" :key="locale" @click="switchLocale(locale)" :active="$i18n && $i18n.locale === locale">
      {{ getTranslationText(locale) }}
    </b-dropdown-item>
  </div>
</template>
<script>
export default {
    name: 'LanguagePicker',
    data() {
        return {
            locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
        };
    },
    methods: {
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                this.$store.commit('setLocale', locale);
            }
        },
        getTranslationText(locale) {
            const index = `admin.common.language.${locale}`;
            return this.$t(index);
        }
    },
};
</script>
