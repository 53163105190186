import getPayoutMode from "@/model/Company/PayoutMode";

export default class CompanyInfo {
    constructor({id, name, displayName, paymentThreshold, payinitThreshold, apiVersion, iban, appToken, enabled, walletByDefault,
        walletType, bankData, hookService, approvalRules, percentageAmountFee, email, retryMechanismValue, lowBalanceDaysLimit,
        payoutMode, paymentsPerUserLimit, paymentsRegPerUserLimit, userCreationLimit, userCreationLimitTimeframe, userIbanChangeLimit,
        userIbanChangeLimitTimeframe, closedPayrollPayPeriodModification, oauthInfo}) {

        this.id = id; // Int = 0
        this.name = name; // String = "",
        this.displayName = displayName; // String = "",
        this.paymentThreshold = paymentThreshold; //  BigDecimal("0.00"),
        this.payinitThreshold = payinitThreshold; //  BigDecimal("0.00"),
        this.apiVersion = apiVersion; //  Int = 1,
        this.iban = iban; // String = "NL23ABNA0809841797"
        this.appToken = appToken; // String = "",
        this.enabled = enabled; // Boolean = false,
        this.walletByDefault = walletByDefault; //  Boolean = false,
        this.walletType = walletType; //  Int = 0, (0 = None, 1 = WAL, 2 = WALC)
        this.bankData = bankData; // BankData = null,
        this.oauthInfo = oauthInfo; // OauthInfo? = null,
        this.hookService = hookService; // Array<OauthInfo>? = null,
        this.approvalRules = approvalRules; // Array<ApprovalRule> = arrayListOf(),
        this.percentageAmountFee = percentageAmountFee; // BigDecimal("0.00"),
        this.email = email; // String = "",
        this.retryMechanismValue = retryMechanismValue; // Int = 5, // README - SET THIS TO 1 if you don't want retry mechanism
        this.lowBalanceDaysLimit = lowBalanceDaysLimit; //  Int? = null, // low balance reimbursement is generated if this limit is reached
        this.payoutMode = getPayoutMode(payoutMode); // PayoutMode? = null, // Manual or Auto
        this.paymentsPerUserLimit = paymentsPerUserLimit; // Int? = null,
        this.paymentsRegPerUserLimit = paymentsRegPerUserLimit; // Int? = null,
        this.userCreationLimit = userCreationLimit; //Int = 1000, How many users can be created without approval per timeframe.
        this.userCreationLimitTimeframe = userCreationLimitTimeframe; // Long = 24, The timeframe of the user creation limit in hours, defaults to 24.
        this.userIbanChangeLimit = userIbanChangeLimit; //  Int = 1,
        this.userIbanChangeLimitTimeframe = userIbanChangeLimitTimeframe; // Int = 180, The timeframe of the user creation limit in days, defaults to 180.
        this.closedPayrollPayPeriodModification = closedPayrollPayPeriodModification; // Int = 1
    }
}
