import PaymentType from '@/utils/PaymentType';
import AxiosFactory from "@/services/AxiosFactory";

class ApiService {
    constructor(axiosInstance, endPoints, vueXStoreInstance, router) {
        this.store = vueXStoreInstance;
        this.endPoints = endPoints;
        this.axios = axiosInstance;
        this.router = router;
        this.config = {
            headers: {'Access-Control-Allow-Origin': '*'}
        };
        this.blobConfig = {
            headers: {'Access-Control-Allow-Origin': '*', 'Accept': 'application/octet-stream'},
            responseType: 'blob',
        };
        this.urlBase = process.env.VUE_APP_API_BASEURL;
        // console.log(`VUE_APP_API_BASEURL: ${process.env.VUE_APP_API_BASEURL}`);
        // console.log(`VUE_APP_ENV: ${process.env.VUE_APP_ENV}`);
    }

    setHeaderJwtToken(jwtToken) {
        this.axios.https.defaults.headers.Authorization = `Bearer ${jwtToken}`;
        this.axios.httpsRetry.defaults.headers.Authorization = `Bearer ${jwtToken}`;
    }

    getToken() {
        return new Promise(((resolve, reject) => this.axios.https.get(this.endPoints.getToken)
            .then((res) => resolve(res))
            .catch((error) => reject(error))));
    }

    async getMainTransactions(isWal, pageNumber, pageSize, sortBy, order, companies, paymentType, status, searchTerms, fromDate, toDate, execFromDate, execToDate) {
        const url = isWal ? this.endPoints.getWalTransactions : this.endPoints.getTransactions;
        if (!isWal &&
            status.includes('PAY_COMPLETED') && !status.includes('EXECUTED')) {
            status.push('EXECUTED');
        }
        return this.getTransactions(url, pageNumber, pageSize, sortBy, order, companies, paymentType, status, searchTerms, fromDate, toDate, execFromDate, execToDate);
    }
    async getPisTransactions(pageNumber, pageSize, sortBy, order, companies, paymentType, status, searchTerms, fromDate, toDate, execFromDate, execToDate) {
            if(status.includes('PAY_COMPLETED') && !status.includes('EXECUTED') ){
            status.push('EXECUTED');
        }
        return this.getTransactions(this.endPoints.getPisTransactions, pageNumber, pageSize, sortBy, order, companies, paymentType, status, searchTerms, fromDate, toDate, execFromDate, execToDate);
    }

    async getTransactions(url, pageNumber, pageSize, sortBy, order, companies, paymentType, status, searchTerms, fromDate, toDate, execFromDate, execToDate) {
        const companiesQueryParam = companies.length > 0 ? `&companyIds=${companies.reduce((acc, val) => `${acc},${val}`)}` : '';
        const searchQueryParam = searchTerms.length ? `&search=${searchTerms}` : '';
        const paymentTypeQueryParam = paymentType.length > 0 ? `&paymentType=${paymentType.reduce((acc, val) => `${acc},${val}`)}` : '';
        const statusQueryParam = status.length > 0 ? `&status=${status.reduce((acc, val) => `${acc},${val}`)}` : '';
        const fromDateQueryParam = fromDate ? `&dateFrom=${fromDate}` : '';
        const toDateQueryParam = toDate ? `&dateTo=${toDate}` : '';
        const fromExecDateQueryParam = execFromDate ? `&execDateFrom=${execFromDate}` : '';
        const toExecDateQueryParam = execToDate ? `&execDateTo=${execToDate}` : '';
        return new Promise((resolve, reject) => this.axios.httpsRetry.get(
            `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${sortBy}&order=${order}${companiesQueryParam}${statusQueryParam}${searchQueryParam}${paymentTypeQueryParam}${fromDateQueryParam}${toDateQueryParam}${fromExecDateQueryParam}${toExecDateQueryParam}`,
            this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getUserData(pageNumber, pageSize, orderBy, order, isActive, companies, userType, status, inAppStatus, searchTerms) {
        const url = this.endPoints.getUserData;
        const userTypesQueryParam = userType.length === 1 ? `&userType=${this.mapUserType(userType[0])}` : '';
        const companiesQueryParam = companies.length > 0 ? `&companyIds=${companies.reduce((acc, val) => `${acc},${val}`)}` : '';
        const statusQueryParam = status.length > 0 ? `&statuses=${status.reduce((acc, val) => `${acc},${val}`)}` : '';
        const inAppStatusQueryParam = inAppStatus.length > 0 ? `&inApp=${inAppStatus.reduce((acc, val) => `${acc},${val}`)}` : '';
        const searchQueryParam = searchTerms.length > 0 ? `&search=${searchTerms}` : '';
        return this.axios.httpsRetry.get(
            `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}${companiesQueryParam}${userTypesQueryParam}${statusQueryParam}${inAppStatusQueryParam}${searchQueryParam}`,
            this.config)
            .then((res) => res)
            .catch((error) => error);
    }

    mapUserType(userType) {
        return userType === 'WALC' ? 'WALLET_USER' : 'PIS_USER';
    }

    getPostData(body, url, config, pageNumber, pageSize, field, order) {
        const mv = false;
        return new Promise((resolve, reject) => this.axios.https.post(`${url}/${pageNumber}/${pageSize}/${field}/${order}/${mv}`, body, config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    /**
     * @deprecated [#1]
     * */
    getGigsShift(workerId, companyId, pageNumber, pageSize, order, orderBy, dateFrom, dateTo, search) {
        const url = this.endPoints.getGigsShift
            .replace('{workerId}', workerId)
            .replace('{companyId}', companyId);
        const pageNumberQueryPram = `?pageNumber=${pageNumber}`;
        const pageSizeQueryPram = pageSize ? `&pageSize=${pageSize}` : '';
        const dateFromQueryPram = dateFrom ? `&dateFrom=${dateFrom}` : '';
        const dateToQueryPram = dateTo ? `&dateTo=${dateTo}` : '';
        const orderQueryPram = order ? `&order=${order}` : '';
        const orderByQueryPram = orderBy ? `&orderBy=${orderBy}` : '';
        const searchQueryPram = search ? `&search=${search}` : '';
        return new Promise((resolve, reject) => this.axios.https.get(`${url}${pageNumberQueryPram}${pageSizeQueryPram}${dateFromQueryPram}${dateToQueryPram}${orderQueryPram}${orderByQueryPram}${searchQueryPram}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getGigsWallet(workerId, companyId) {
        const url = this.endPoints.getGigsWallet
            .replace('{workerId}', workerId)
            .replace('{companyId}', companyId);
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getCompanies() {
        const url = this.endPoints.getCompanies;
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    modifyUserStatus(workerId, companyId, isActive) {
        const url = this.endPoints.enableUser
            .replace('{workerId}', workerId)
            .replace('{companyId}', companyId)
            .replace('{isActive}', isActive);
        return new Promise((resolve, reject) => this.axios.https.post(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    deleteUserPermanently(workerId, companyId) {
        const url = this.endPoints.deleteUser
            .replace('{id}', workerId)
            .replace('{cid}', companyId);
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getErrorCount() {
        return new Promise((resolve, reject) => this.axios.httpsRetry.get(this.endPoints.getErrorCount)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getCompanyBalance() {
        return new Promise((resolve, reject) => this.axios.httpsRetry.get(this.endPoints.getCompanyBalance)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getErrorServer(pageNumber, pageSize, orderBy, order, errorType, searchTerms) {
        const url = this.endPoints.getErrorServer;
        // Sanitize error Query Param. In case is empty, force to show all kind of errors.
        const errorQueryParam = errorType.length === 0 ? ['API', 'MAPI', 'DIRECT_PAYMENT', 'WALLET_PAYOUT'] : errorType;
        return this.getError(url, pageNumber, pageSize, orderBy, order, [], undefined, errorQueryParam, searchTerms);
    }

    getError(url, pageNumber, pageSize, orderBy, order, companies, status, errorType, searchTerms) {
        const companiesQueryParam = companies.length > 0 ? `&companyIds=${companies.reduce((acc, val) => `${acc},${val}`)}` : '';
        const searchQueryParam = searchTerms.length > 0 ? `&search=${searchTerms}` : '';
        const statusQueryParam = status ? `&status=${status}` : '';
        const errorTypeQueryParam = errorType.length > 0 ? `&errorType=${errorType.reduce((acc, val) => `${acc},${val}`)}` : '';
        return new Promise((resolve, reject) => this.axios.https.get(`${url}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}${statusQueryParam}${errorTypeQueryParam}${companiesQueryParam}${searchQueryParam}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    approvePisPayment(pid) {
        const url = this.endPoints.approvePisPayment.replace('{pid}', pid);
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    refusePisPayment(pid, reason) {
        const url = this.endPoints.refusePisPayment.replace('{pid}', pid);
        const body = {reason: reason};
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, body, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    approveWalcPayment(pid) {
        const url = this.endPoints.approveWalcPayment.replace('{pid}', pid);
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    refuseWalcTransaction(pid, reason) {
        const url = this.endPoints.refuseWalcPayment.replace('{pid}', pid);
        const body = {reason: reason};
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, body, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getPayrollsData(pageNumber, pageSize, orderBy, order, dateFrom, dateTo, periodId, status, companies, search) {
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getPayrolls}`),
            {
                pageNumber,
                pageSize,
                orderBy,
                order,
                dateFrom: dateFrom ? dateFrom : '',
                dateTo: dateTo ? dateTo : '',
                groupId: periodId.length > 0 ? periodId.join(',') : '',
                status: status.length > 0 ? status.join(',') : '',
                search: search.length > 0 ? search : '',
                companyIds: companies.length > 0 ? companies.join(','): ''
            }
        );
        return new Promise((resolve, reject) => this.axios.https.get(url)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getPayrollPeriodsData() {
        const url = this.endPoints.getPayrollPeriods;
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getBalanceAndDepositData(pageNumber, pageSize, orderBy, order, type, status, searchTerms, companyIds) {
        const url = this.endPoints.getBalanceAndDeposit;
        const pageNumberQueryParam = `?pageNumber=${pageNumber}`;
        const pageSizeQueryParam = pageSize ? `&pageSize=${pageSize}` : '';
        const orderByQueryParam = orderBy ? `&orderBy=${orderBy}` : '';
        const orderQueryParam = order ? `&order=${order}` : '';
        const userTypeQueryParam = type.length > 0 ? `&depositType=${type}` : '';
        const statusQueryParam = status.length > 0 ? `&status=${status}` : '';
        const searchQueryParam = searchTerms ? `&searchTerms=${searchTerms}` : '';
        const companiesQueryParam = companyIds.length > 0 ? `&companyIds=${companyIds.reduce((acc, val) => `${acc},${val}`)}` : '';
        return new Promise((resolve, reject) => this.axios.https.get(`${url}${pageNumberQueryParam}${pageSizeQueryParam}${orderQueryParam}${orderByQueryParam}${userTypeQueryParam}${statusQueryParam}${companiesQueryParam}${searchQueryParam}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    makeDeposit(depositRequest) {
        return new Promise((resolve, reject) => this.axios.https.put(`${this.endPoints.putNewDeposit}`, depositRequest)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    /**
     *
     * @deprecated [#2]
     * */
    updateUser(workerId, companyId, newUser) {
        const url = this.endPoints.updateUser
            .replace('{workerId}', workerId)
            .replace('{companyId}', companyId);
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, newUser)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    scheduleNotification(notificationBody) {
        const url = this.endPoints.postNotification;
        return new Promise((resolve, reject) => this.axios.https.post(url, notificationBody)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getTemplates() {
        const url = this.endPoints.getTemplates;
        return new Promise((resolve, reject) => this.axios.https.get(url)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getNotifications(isMainNotification, pageNumber, pageSize, orderBy, order, type, searchTerms, companyId) {
        const url = isMainNotification ? this.endPoints.getMainNotifications : this.endPoints.getNotifications;
        const pageNumberQueryParam = `?pageNumber=${pageNumber}`;
        const pageSizeQueryParam = pageSize ? `&pageSize=${pageSize}` : '';
        const orderByQueryParam = orderBy ? `&orderBy=${orderBy}` : '';
        const orderQueryParam = order ? `&order=${order}` : '';
        const notificationTypeQueryParam = type.length > 0 ? `&type=${type}` : '';
        const searchQueryParam = searchTerms ? `&searchTerms=${searchTerms}` : '';
        const companyQueryParam = `&companyId=${companyId}`;
        const endpoint = `${url}${pageNumberQueryParam}${pageSizeQueryParam}${orderQueryParam}${orderByQueryParam}${notificationTypeQueryParam}${searchQueryParam}${companyQueryParam}`;
        return new Promise((resolve, reject) => this.axios.https.get(endpoint)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    updateAuthUserImage(formData) {
        const url = this.endPoints.updateAuthUserImage;
        return new Promise((resolve, reject) =>
            this.axios.https.post(`${url}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((res) => resolve(res))
                .catch((error) => reject(error)));
    }

    updateUserPassword(body) {
        const url = this.endPoints.updateAuthUserPassword;
        return new Promise((resolve, reject) => this.axios.https.post(`${url}`, body)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    requestImage(url, customHeader) {
        return new Promise((resolve, reject) =>
            this.axios.https.get(`${url}`, customHeader)
                .then((res) => {
                    if (res.data.byteLength > 0) {
                        const image = btoa(new Uint8Array(res.data)
                            .reduce((data, byte) => data + String.fromCharCode(byte), ''));
                        return resolve(`data:${res.headers['content-type'].toLowerCase()};base64,${image}`);
                    }
                    // If return an empty array means that there is not yet a new image, so we will user user.picture
                    return resolve('');
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
        );
    }

    getAuthUserImageById(userId) {
        const url = this.endPoints.getAuthUserImage.replace('{userId}', userId);
        const customHeader = {
            headers: {'Accept': '*/*'},
            responseType: 'arraybuffer',
        };
        return this.requestImage(url, customHeader);
    }

    getAuthUserImage() {
        const url = this.endPoints.updateAuthUserImage;
        const customHeader = {
            headers: {'Accept': 'image/jpeg'},
            responseType: 'arraybuffer'
        };
        return this.requestImage(url, customHeader);
    }

    updateAuthUser(userId, user) {
        const url = this.endPoints.updateAuthUser
            .replace('{userId}', userId);
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, user)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    addAuthUsers(user) {
        const url = this.endPoints.addAuthUsers;
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, user)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getAuthUsers() {
        const url = this.endPoints.getAuthUsers;
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getAuthUserRoles() {
        const url = this.endPoints.getAuthUserRoles;
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getMobileUserInfo(mId) {
        const url = this.endPoints.getMuserInfo.replace('{mid}', mId);
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    performPisPayment(payment) {
        const url = this.endPoints.executePis;
        return new Promise((resolve, reject) => this.axios.https.post(url, payment)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getCompaniesData() {
        const url = this.endPoints.getCompaniesData;
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    updateCompanyData(companyId, payload) {
        const url = this.endPoints.updateCompanyData
            .replaceAll('{companyId}', companyId);
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, payload, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    approveWalTransaction(pid) {
        const url = this.endPoints.confirmWalTransaction.replaceAll('{pid}', pid);
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    approveWaloutTransaction(pid) {
        const url = this.endPoints.putConfirmWalOut.replaceAll('{pid}', pid);
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    approveWalcoutTransaction(pid) {
        const url = this.endPoints.putConfirmWalcOut.replaceAll('{pid}', pid);
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    refuseWalTransaction(pid, reason) {
        const url = this.endPoints.refuseWalTransaction.replaceAll('{pid}', pid);
        const body = {reason: reason};
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, body, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    refuseWalOutTransaction(pid, reason) {
        const url = this.endPoints.putRefuseWalOut.replaceAll('{pid}', pid);
        const body = {reason: reason};
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, body, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    refuseWalcOutTransaction(pid, reason) {
        const url = this.endPoints.putRefuseWalcOut.replaceAll('{pid}', pid);
        const body = {reason: reason};
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, body, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getInvoices(pageNumber, pageSize, orderBy, order, dateFrom, dateTo, payDateFrom, payDateTo, payoutDateFrom, payoutDateTo, status, companies, search) {
        const url = this.endPoints.getInvoices;
        const pageNumberQueryParam = `?pageNumber=${pageNumber}`;
        const pageSizeQueryParam = pageSize ? `&pageSize=${pageSize}` : '';
        const orderByQueryParam = orderBy ? `&orderBy=${orderBy}` : '';
        const orderQueryParam = order ? `&order=${order}` : '';
        const dateFromQueryParam = dateFrom ? `&dateFrom=${dateFrom}` : '';
        const dateToQueryParam = dateTo ? `&dateTo=${dateTo}` : '';
        const payDateFromQueryParam = payDateFrom ? `&payDateFrom=${payDateFrom}` : '';
        const payDateToQueryParam = payDateTo ? `&payDateTo=${payDateTo}` : '';
        const payoutDateFromQueryParam = payoutDateFrom ? `&payoutDateFrom=${payoutDateFrom}` : '';
        const payoutDateToQueryParam = payoutDateTo ? `&payoutDateTo=${payoutDateTo}` : '';
        const statusTerm = status.length > 0 ? `&status=${status.join(',')}` : '';
        const companyQueryParam = companies.length > 0 ? `&companyIds=${companies.join(',')}` : '';
        const searchTerm = search.length > 0 ? `&search=${search}` : '';
        return new Promise((resolve, reject) => this.axios.https.get(
            `${url}${pageNumberQueryParam}${pageSizeQueryParam}${dateFromQueryParam}${dateToQueryParam}${payDateFromQueryParam}${payDateToQueryParam}${orderQueryParam}${orderByQueryParam}${payoutDateFromQueryParam}${payoutDateToQueryParam}${statusTerm}${companyQueryParam}${searchTerm}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getInvoice(relatedInvoicesIds, companyId) {
        const responses = relatedInvoicesIds
            .map(invoiceId => this.endPoints.getInvoiceDetail
                .replaceAll('{companyId}', companyId)
                .replaceAll('{invoiceId}', invoiceId))
            .map(url => this.axios.https.get(`${url}`));
        return Promise.all([...responses])
            .then(response => Promise.resolve(response.map(res => res.data)))
            .catch(response => Promise.reject(response));
    }

    getUserDataRequest(pageNumber, pageSize, orderBy, order, isActive, companies, userType, status, searchTerms) {
        const url = this.endPoints.getUserData;
        const userTypesQueryParam = userType.length === 1 ? `&userType=${this.mapUserType(userType[0])}` : '';
        const companiesQueryParam = companies.length > 0 ? `&companyIds=${companies.reduce((acc, val) => `${acc},${val}`)}` : '';
        const statusQueryParam = status.length === 1 ? `&status=${status}` : '';
        const searchQueryParam = searchTerms.length > 0 ? `&search=${searchTerms}` : '';
        return this.axios.httpsRetry.get(`${url}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}&isActive=${isActive}${companiesQueryParam}${userTypesQueryParam}${statusQueryParam}${searchQueryParam}`, this.config);
    }

    async performMultipleRequests(listOfRequests) {
        return await Promise.allSettled([...listOfRequests])
            .then(response => Promise.resolve(response.map(res => res.value.data)))
            .catch(response => Promise.reject(response));
    }

    updatePayment(updatePaymentRequest, paymentType) {
        let url = '';
        if (paymentType === PaymentType.PIS.symbol) {
            url = this.endPoints.putUpdateTxPis;
        } else if (paymentType === PaymentType.WAL.symbol || paymentType === PaymentType.PAYOUTAUTO.symbol ) {
            url = this.endPoints.putUpdateTxWal;
        } else {
            url = this.endPoints.putUpdateTxWalc;
        }
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, updatePaymentRequest, this.config)
            .then(res => resolve(res))
            .catch(error => reject(error)));
    }

    updateUserStatus(payload) {
        const url = this.endPoints.postUpdateUserStatus;
        return this.axios.https.post(url, payload, this.config)
            .then((res) => res)
            .catch((error) => error);
    }

    regenerateDeposit(depositId) {
        const url = this.endPoints.putRegenerateDeposit.replaceAll('{depositId}', depositId);
        return this.axios.https.put(url, this.config)
            .then((res) => res)
            .catch((error) => error);
    }

    makePayrollModifiable(pid) {
        const url = this.endPoints.putMakePayrollModifiable
            .replaceAll('{pid}', pid);
        return this.axios.https.put(url)
            .then((res) => res)
            .catch((error) => error);
    }

    confirmBatchTransactions(transactions) {
        const url = this.endPoints.putConfirmBatchTransactions;
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, transactions, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    refuseBatchTransactions(transactions) {
        const url = this.endPoints.putRefuseBatchTransactions;
        return new Promise((resolve, reject) => this.axios.https.put(`${url}`, transactions, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getTransactionDetail(isWal, isPis, isPayout, transactionId) {
        let url = '';
        switch (true) {
        case (isPis):
            url = this.endPoints.getPisDetails.replaceAll('{id}', transactionId);
            break;
        case (isWal && isPayout):
            url = this.endPoints.getWalOutDetails.replaceAll('{id}', transactionId);
            break;
        case (!isWal && isPayout):
            url = this.endPoints.getWalcOutDetails.replaceAll('{id}', transactionId);
            break;
        case (isWal && !isPayout):
            url = this.endPoints.getWalDetails.replaceAll('{id}', transactionId);
            break;
        case (!isWal && !isPayout):
            url = this.endPoints.getWalcDetails.replaceAll('{id}', transactionId);
            break;
        }
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    getWorkerInfoWithHistory(workerId, companyId) {
        const url = this.endPoints.getWorkerInfos
            .replaceAll('{workerId}', workerId)
            .replaceAll('{companyId}', companyId);
        return new Promise((resolve, reject) => this.axios.https.get(`${url}`, this.config)
            .then((res) => resolve(res))
            .catch((error) => reject(error)));
    }

    setQueryParams(url, queryParams) {
        for (const [key, value] of Object.entries(queryParams)) {
            if (value) url.searchParams.set(key, value);
        }
        return url;

    }

    exportWorkers(pageNumber, pageSize, orderBy, order, companies, userType, status, inAppStatus, format, searchTerms) {
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getWorkersExport}`),
            {
                pageNumber,
                pageSize,
                orderBy,
                order,
                userType: userType.length === 1 ? this.mapUserType(userType[0]) : '',
                statuses: status.length > 0 ? status.reduce((acc, val) => `${acc},${val}`) : '',
                companyIds: companies.length > 0 ? companies.reduce((acc, val) => `${acc},${val}`) : '',
                inApp: inAppStatus.length > 0 ? inAppStatus.reduce((acc, val) => `${acc},${val}`) : '',
                search: searchTerms.length > 0 ? searchTerms : '',
                format: format ? format : 'xlsx',
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }
    exportPartialWorkers(workersIds, companyIds, format){
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getWorkersPartialExport}`),
            {
                workersIds,
                companyIds,
                format
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }

    exportPartialTransactions(transactionIds, companyIds, format){
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getTransactionsPartialExport}`),
            {
                transactionIds,
                companyIds,
                format
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }
    exportPartialWalTransactions(transactionIds, companyIds, format){
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getWalTransactionsPartialExport}`),
            {
                transactionIds,
                companyIds,
                format
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }
    exportPartialPisTransactions(transactionIds, companyIds, format){
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getPisTransactionsPartialExport}`),
            {
                transactionIds,
                companyIds,
                format
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }

    exportPisTransaction(pageNumber, pageSize, orderBy, order, companies, paymentType, status, searchTerms, fromDate, toDate, execFromDate, execToDate, format) {
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getPisTransactionsExport}`),
            {
                pageNumber,
                pageSize,
                orderBy,
                order,
                paymentType: paymentType.length > 0 ? paymentType.reduce((acc, val) => `${acc},${val}`) : '',
                statuses: status.length > 0 ? status.reduce((acc, val) => `${acc},${val}`) : '',
                companyIds: companies.length > 0 ? companies.reduce((acc, val) => `${acc},${val}`) : '',
                search: searchTerms.length > 0 ? searchTerms : '',
                dateFrom: fromDate ? fromDate : '',
                dateTo: toDate ? toDate : '',
                execDateFrom: execFromDate ? execFromDate : '',
                execDateTo: execToDate ? execToDate : '',
                format: format ? format : 'xlsx',
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }
    exportTransaction(isWal, pageNumber, pageSize, orderBy, order, companies, paymentType, status, searchTerms, fromDate, toDate, execFromDate, execToDate, format) {
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${isWal ? this.endPoints.getWalTransactionsExport : this.endPoints.getTransactionsExport}`),
            {
                pageNumber,
                pageSize,
                orderBy,
                order,
                paymentType: paymentType.length > 0 ? paymentType.reduce((acc, val) => `${acc},${val}`) : '',
                statuses: status.length > 0 ? status.reduce((acc, val) => `${acc},${val}`) : '',
                companyIds: companies.length > 0 ? companies.reduce((acc, val) => `${acc},${val}`) : '',
                search: searchTerms.length > 0 ? searchTerms : '',
                dateFrom: fromDate ? fromDate : '',
                dateTo: toDate ? toDate : '',
                execDateFrom: execFromDate ? execFromDate : '',
                execDateTo: execToDate ? execToDate : '',
                format: format ? format : 'xlsx',
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }

    exportPayrolls(pageNumber, pageSize, orderBy, order, dateFrom, dateTo, groupId, status, search, format) {
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getPayrollsExport}`),
            {
                pageNumber,
                pageSize,
                orderBy,
                order,
                dateFrom: dateFrom ? dateFrom : '',
                dateTo: dateTo ? dateTo : '',
                search: search.length > 0 ? search : '',
                groupId: groupId.length > 0 ? groupId.join(',') : '',
                format: format ? format : 'xlsx',
            }
        );
        new AxiosFactory().https();
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);

    }

    exportInvoices(pageNumber, pageSize, orderBy, order, dateFrom, dateTo, payDateFrom, payDateTo, payoutDateFrom, payoutDateTo, status, companies, search, format) {
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getInvoicesExport}`),
            {
                pageNumber,
                pageSize,
                orderBy,
                order,
                dateFrom: dateFrom ? dateFrom : '',
                dateTo: dateTo ? dateTo : '',
                payDateFrom: payDateFrom ? payDateFrom : '',
                payDateTo: payDateTo ? payDateTo : '',
                payoutDateFrom: payoutDateFrom ? payoutDateFrom : '',
                payoutDateTo: payoutDateTo ? payoutDateTo : '',
                companyIds: companies.length > 0 ? companies.reduce((acc, val) => `${acc},${val}`) : '',
                search: search.length > 0 ? search : '',
                format: format ? format : 'xlsx',
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }

    exportDeposits(pageNumber, pageSize, orderBy, order, type, status, searchTerms, companyIds, format) {
        const url = this.setQueryParams(
            new URL(`${this.urlBase}${this.endPoints.getDepositsExport}`),
            {
                pageNumber,
                pageSize,
                orderBy,
                order,
                status: status.length > 0 ? status.reduce((acc, val) => `${acc},${val}`) : '',
                depositType: type.length > 0 ? type.reduce((acc, val) => `${acc}${val}`) : '',
                companyIds: companyIds.length > 0 ? companyIds.reduce((acc, val) => `${acc},${val}`) : '',
                search: searchTerms.length > 0 ? searchTerms : '',
                format: format ? format : 'xlsx',
            }
        );
        return this.axios.httpsRetry.get(url, this.blobConfig)
            .then((res) => res)
            .catch((error) => error);
    }
    onboardCompany(companyDto){
        const url = this.endPoints.postOnBoardCompany;
        return new Promise((resolve, reject) => this.axios.https.post(url, companyDto, this.config)
            .then(res => resolve(res))
            .catch(error => reject(error)));
    }
}

let api;
const getApi = (axiosInstance, endPoints, store, router) => {
    api = api || new ApiService(axiosInstance, endPoints, store, router);
    return api;
};

export default getApi;
