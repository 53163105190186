import authGuard from '@/auth/authGuard';

export default [
    {
        path: '/',
        name: '#/workers/active',
        component: () => import('@/views/Workers.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Workers - Payday Admin Panel\'',
        },
    },
    {
        path: '/workers/active',
        name: '#/workers/active',
        component: () => import('@/views/Workers.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Workers - Payday Admin Panel',
        },
    },
    {
        path: '/transactions',
        name: '#/transactions',
        component: () => import('@/views/Transactions.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Transactions - Payday Admin Panel',
        },
    },
    {
        path: '/transactions/wal',
        name: '#/transactions/wal',
        component: () => import('@/views/TransactionsWal.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Transactions - Payday Admin Panel',
        },
    },
    {
        path: '/transactions/pis',
        name: '#/transactions/pis',
        component: () => import('@/views/TransactionsPis.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Transactions - Payday Admin Panel',
        },
    },
    {
        path: '/payroll',
        name: '#/payroll',
        component: () => import('@/views/Payrolls.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Payrolls - Payday Admin Panel',
        },
    },
    {
        path: '/invoice',
        name: '#/invoice',
        component: () => import('@/views/Invoices.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Invoices - Payday Admin Panel',
        },
    },
    {
        path: '/errors/server',
        name: '#/errors/server',
        component: () => import('@/views/ErrorLogServer.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Errors - Payday Admin Panel',
        },
    },
    {
        path: '/notification/overview',
        name: '#/notification/overview',
        component: () => import('@/views/NotificationOverview.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Notification - Payday Admin Panel',
        },
    },
    {
        path: '/notification/settings',
        name: '#/notification/settings',
        component: () => import('@/views/NotificationSetting.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/balance',
        name: '#/balance',
        component: () => import('@/views/BalanceDeposit.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Notification Setting - Payday Admin Panel',
        },
    },
    {
        path: '/companies',
        name: '#/companies',
        component: () => import('@/views/Companies.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Companies Info - Payday Admin Panel',
        },
    },
    {
        path: '/token',
        name: '#/token',
        component: () => import('@/views/Token.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: 'Token - Payday Admin Panel',
        },
    },
    {
        path: '/login',
        name: '#/login',
        component: () => import('@/views/Login.vue'),
        beforeEnter: authGuard,
        meta: {
            requiresAuth: false,
            title: 'Login - Payday Admin Panel',
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/PageNotFound.vue'),
        meta: {
            requiresAuth: false,
            title: 'Not Found - Payday Admin Panel',
        },
    },
    {
        path: '*',
        name: '*',
        component: () => import('@/views/PageNotFound.vue'),
        meta: {
            requiresAuth: false,
            title: 'Not Found - Payday Admin Panel',
        },
    },
];
