<template>
  <b-nav class="left-menu" vertical>
    <b-nav-item v-for="(item, index) in navItems.filter(nav => !nav.disabled)"
                :key="index"
                :disabled="item.disabled"
                :href="item.url"
                :id="item.title"
                :class="`my-navlink ${hasRoute(item) ? 'active-menu-item' : 'inactive-menu-item'}`"
                :style="{display: item.disabled ? 'none' : 'flex' }">
      <div class="my-badge-container">
        <b-img
            :src="hasRoute(item) ? item.iconActive : item.icon"
            class="nav-menu-icon"
            :alt="item.title">
        </b-img>
        <b-badge variant="light"
                 :class="isBadgeActive(item)"
                 :style="{width: getRightWidthForNotificationBadge(item.notificationNumber)}"
                 v-if="item.hasNotifications">
          {{ item.notificationNumber }}
          <span class="sr-only">{{ $t('admin.common.action-required') }}</span>
        </b-badge>
      </div>
      <b-tooltip v-if="item.submenu"
                 :target="item.title"
                 ref="tooltip"
                 custom-class="menu-side-tooltip"
                 triggers="hover"
                 placement="right">
        <b-nav v-if="item.submenu" class="sub-nav" vertical>
          <b-nav-item v-for="(subitem, subindex) in item.submenu"
                      :key="subindex"
                      :id="subitem.title"
                      :active="hasRoute(subitem)"
                      :disabled="subitem.disabled"
                      :style="{display: subitem.disabled ?'none' : 'flex' }"
                      :href="subitem.url">
            <div class="sub-menu-item">
              <p>{{ subitem.title }}</p>
              <div>
                <b-badge v-if="subitem.hasNotifications" variant="light"
                         :class="isBadgeActive(subitem, false)">{{ subitem.notificationNumber }}
                  <span class="sr-only">{{ $t('admin.common.action-required') }}</span>
                </b-badge>
              </div>
            </div>
          </b-nav-item>
        </b-nav>
      </b-tooltip>
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
    name: 'MainMenu',
    props: {
        navItems: {
            type: Array,
            default: () => []
        },
        hasRoute: {
            type: Function,
            default: () => {
            }
        },
    },
    methods: {
        isBadgeActive(item, isMyBadge = true) {
            const baseClass = isMyBadge ? "my-badge" : "my-sub-badge";
            if (this.hasRoute(item)) {
                return `${baseClass} active-badge`;
            }
            return `${baseClass} inactive-badge`;
        },
        getRightWidthForNotificationBadge(number) {
            const numberInString = `${number}`;
            const numberSize = number <= 0 ? 0 : numberInString.length - 1;
            return `${20 + (numberSize * 5) + numberSize}px`;
        },
    }
};
</script>

<style scoped>

.sub-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.sub-menu-item > * {
  margin: 0;
}

.menu-side-tooltip > .tooltip-inner {
  background-color: #000740;
  color: #fff;
}

ul.nav.left-menu {
  margin: 1.2rem 0 0 0;
  padding: 0;
}

.nav-menu-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.nav-item {
  padding-left: 0.5rem;
}

.tooltip-inner > ul > li > a {
  padding-left: 5px;
  padding-right: 5px;
}

.nav-item > a {
  color: white;
}

.nav-item > a.active {
  color: #37ABA1;
}

.my-badge-container {
  display: inline-block;
}

.my-badge {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.5px 5px;
  gap: 10px;
  width: 20px;
  height: 20px;
  background: #D21B02;
  color: #FFFFFF;
  text-align: center;
  border-radius: 16px;
  position: relative;
  top: -30px;
  left: 13px;
}

.my-sub-badge {
  box-sizing: border-box;
  display: inline;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.5px 5px;
  margin-left: 5px;
  gap: 10px;
  width: 20px;
  height: 20px;
  background: #D21B02;
  color: #FFFFFF;
  text-align: center;
  border-radius: 16px;
}

.active-badge {
  border: 1px solid #FFFFFF;
}

.inactive-badge {
  border: 1px solid #A4A6B7;
}

.my-badge > span {
  width: 6px;
  height: 11px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.my-navlink {
  width: 100%;
  height: 50px;
  padding-top: 0.2rem;
  margin-bottom: 10px;
}

.active-menu-item {
  background-color: rgb(255, 255, 255, 0.15);
}
</style>
