<template>
  <b-card v-if="shouldShowBalanceCard" class="balance-card">
    <b-row>
      <b-col>{{ $t('admin.common.cash-remaining') }}</b-col>
    </b-row>
    <b-row>
      <b-col>€ {{ companyBalance.availableBalance.toString() }}</b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
    name: 'CashCard',
    props: {
        shouldShowBalanceCard: {
            type: Boolean,
            default: () => false
        },
        companyBalance: {
            type: Object,
            default: () => ({
                availableBalance: 0,
                daysLeft: '0',
                bankBalance: 0,
                companyId: -1,
                companyName: 'N/A',
                dailyAveragePayout: 1.0,
                walletBalance: 1.0,
            })
        },
    }
};
</script>

<style scoped>

.card {
  background-color: #F8F8F8 !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
  border-radius: 0 !important;
}

.card-body {
  padding-right: 3rem;
}

.balance-card {
  color: #000740;
  border-right: 1px solid #CCCCCC !important;
}

.balance-card .row:not(.values) div {
  white-space: nowrap;
}


.balance-card .values {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.balance-card .values div {
  padding-top: 4px;
}

</style>
