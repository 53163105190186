<template>
  <div></div>
</template>

<script>
export default {
    name: 'ToasterUtils',
    methods: {
        makeToast(isSuccess = false, message, toaster = 'b-toaster-top-left') {
            const variant = isSuccess ? 'success' : 'danger';
            const title = isSuccess ? this.$t('admin.common.success') : this.$t('admin.common.failure');
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                toaster: toaster,
                solid: true
            });
        },
    }
};
</script>
