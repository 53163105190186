<template>
  <div class="custom-modal">
    <div class="custom-modal__content">
      <b-row >
        <b-col class="col-3">
          <div v-if="!showUserForm" class="setting-menu-container">
            <h3>{{ $t('admin.settings.common.setting') }}</h3>
            <b-nav class="left-menu" vertical>
              <b-nav-item>
                <b-row v-bind:class="{'active': tabSelected === 0 }" @click="selectTab(0)">
                  <b-col class="col-sm">
                    <b-img class="nav-menu-icon" src="@/assets/images/icon-setting-general.svg"></b-img>
                    {{ $t('admin.settings.menu.general') }}
                  </b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item>
                <b-row v-bind:class="{'active': tabSelected === 1 }" @click="selectTab(1)">
                  <b-col class="col-sm">
                    <b-img class="nav-menu-icon" src="@/assets/images/icon-setting-profile.svg"></b-img>
                    {{ $t('admin.settings.menu.profile') }}
                  </b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item>
                <b-row v-bind:class="{'active': tabSelected === 2 }" @click="selectTab(2)">
                  <b-col class="col-sm">
                    <b-img class="nav-menu-icon" src="@/assets/images/icon-setting-password.svg"></b-img>
                    {{ $t('admin.settings.menu.password') }}
                  </b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item>
                <b-row v-bind:class="{'active': tabSelected === 3 }" @click="selectTab(3)">
                  <b-col class="col-sm">
                    <b-img class="nav-menu-icon" src="@/assets/images/icon-setting-users.svg"></b-img>
                    {{ $t('admin.settings.menu.users') }}
                  </b-col>
                </b-row>
              </b-nav-item>
            </b-nav>
          </div>
          <div v-if="showUserForm" class="setting-menu-container">
            <p @click="showUserCreator">
              <img :alt="$t('admin.settings.common.setting')" src="@/assets/images/icon-go-back-blue.svg"/>
              {{ $t('admin.settings.content.users.user_page.back') }}
            </p>
          </div>
        </b-col>
        <b-col v-if="!showUserForm" class="setting-content-right">
          <b-row class="text-right">
            <b-col class="close-button-padding">
              <b-img class="float-right" src="@/assets/images/icon-close-button-blue.svg"
                     @click="handleCloseButton"/>
            </b-col>
          </b-row>
          <GeneralSettings v-if="tabSelected === 0"></GeneralSettings>
          <ProfileSettings v-if="tabSelected === 1" :make-toast="makeToast"/>
          <PasswordSettings v-if="tabSelected === 2" :make-toast="makeToast"/>
          <UsersSettings v-if="tabSelected === 3" :show-user-creator="showUserCreator"/>
        </b-col>
        <b-col v-if="showUserForm" class="setting-content-right">
          <b-row class="text-right">
            <b-col class="close-button-padding">
              <b-img class="float-right" src="@/assets/images/icon-close-button-blue.svg" @click="handleCloseButton"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </div>
  </div>
</template>
<script>
import GeneralSettings from '@/components/Settings/GeneralSettings.vue';
import ProfileSettings from '@/components/Settings/ProfileSettings.vue';
import PasswordSettings from '@/components/Settings/PasswordSettings.vue';
import UsersSettings from '@/components/Settings/UsersSettings.vue';
import ToasterUtils from '@/utils/ToasterUtils.vue';
import FormatUtils from '@/utils/FormatUtils.vue';

export default {
    name: 'Settings',
    mixins: [ToasterUtils, FormatUtils],
    components: {
        GeneralSettings,
        ProfileSettings,
        PasswordSettings,
        UsersSettings,
    },
    data() {
        return {
            tabSelected: 0,
            showUserForm: false
        };
    },
    props: {
        handleCloseButton: {
            type: Function,
            default: () => {
            },
        }
    },
    methods: {
        selectTab(index) {
            this.tabSelected = index;
        },
        showUserCreator() {
            this.showUserForm = !this.showUserForm;
        }
    }
};
</script>
