import {PIS_WALLET_TYPE, WAL_WALLET_TYPE, WALC_WALLET_TYPE} from '@/store/store';
import PaymentType from '@/utils/PaymentType';
const UNKNOWN_COMPANY = 'Unknown Company Name';

const getters = {
    getAll(state) {
        return state.companies.value;
    },
    getPis(state) {
        return state.companies.value
            .filter(company => company.walletType === PIS_WALLET_TYPE);
    },
    getWalc(state) {
        return state.companies.value
            .filter(company => company.walletType === WALC_WALLET_TYPE);
    },
    getWal(state) {
        return state.companies.value
            .filter(company => company.walletType === WAL_WALLET_TYPE);
    },
    getWalcAndPis(state) {
        return state.companies.value
            .filter(company => company.walletType === PIS_WALLET_TYPE || company.walletType === WALC_WALLET_TYPE );
    },
    getWalletTypeByCompanyId: state =>  companyId => {
        const foundCompanies = state.companies.value
            .filter(company => company.id === companyId);
        if (foundCompanies.length === 0) {
            return 'UNKNOWN_COMPANY';
        }
        if (foundCompanies.length > 0) {
            const walletType = foundCompanies[0].walletType;
            switch (walletType) {
            case PIS_WALLET_TYPE :
                return PaymentType.PIS.symbol;
            case WALC_WALLET_TYPE :
                return PaymentType.WALC.symbol;
            default:
                return PaymentType.WAL.symbol;
            }
        }
        return 'UNKNOWN_COMPANY';
    },
    getWalletTypeByCompanyName: state => companyName => {
        const foundCompanies = state.companies.value
            .filter(company => company.name === companyName);

        if (foundCompanies.length === 0) return 'UNKNOWN_COMPANY';
        if (foundCompanies.length > 0) return foundCompanies[0].walletType;
    },
    getAllTransactions(state) {
        return state.transactionsToConfirm.value;
    },
    getAllWalTransactions(state) {
        return state.walTransactionsToConfirm.value;
    },
    getAllPisTransactions(state) {
        return state.pisTransactionsToConfirm.value;
    },
    getAllWorkers(state) {
        return state.workersToConfirm.value;
    },
    getAllPayrolls(state) {
        return state.payrollsToConfirm.value;
    },
    payrollsLastUpdated(state) {
        return state.payrollsToConfirm.lastUpdated;
    },
    companiesLastUpdated(state) {
        return state.companies.lastUpdated;
    },
    transactionsLastUpdated(state) {
        return state.transactionsToConfirm.lastUpdated;
    },
    walTransactionsLastUpdated(state) {
        return state.walTransactionsToConfirm.lastUpdated;
    },
    pisTransactionsLastUpdated(state) {
        return state.pisTransactionsToConfirm.lastUpdated;
    },
    workerLastUpdated(state) {
        return state.workersToConfirm.lastUpdated;
    },
    isWorkerEmpty(state) {
        return state.workersToConfirm.value.length === 0;
    },
    getLocale(state) {
        return state.locale;
    },
    isLocaleSet(state) {
        return state.locale !== '';
    },
    isFetchingForExport(state) {
        return state.exporters.workers.isFetching ||
            state.exporters.walTransactions.isFetching ||
            state.exporters.transactions.isFetching ||
            state.exporters.invoices.isFetching ||
            state.exporters.deposits.isFetching ||
            state.exporters.payrolls.isFetching;
    },
    downloadedExportFiles(state) {
        return ((state.exporters.workers.fetchEnded && !state.exporters.workers.exported) ? 1 : 0) +
            ((state.exporters.walTransactions.fetchEnded && !state.exporters.walTransactions.exported) ? 1 : 0) +
            ((state.exporters.transactions.fetchEnded && !state.exporters.transactions.exported) ? 1 : 0) +
            ((state.exporters.invoices.fetchEnded && !state.exporters.deposits.exported) ? 1 : 0) +
            ((state.exporters.deposits.fetchEnded && !state.exporters.deposits.exported) ? 1 : 0) +
            ((state.exporters.payrolls.fetchEnded && !state.exporters.payrolls.exported) ? 1 : 0);
    },
    getGlobalExportStatus(state) {
        return Object.keys(state.exporters)
            .filter(key => !state.exporters[key].exported &&
                state.exporters[key].fileName !== ""
            )
            .map(key => ({
                exporter: state.exporters[key],
                type: key
            }));
    },
    getCompanyName: (state) => (id) => {
        return state.companies.value
            .filter(company => company.id === id)
            .map(company => company.displayName)[0] || UNKNOWN_COMPANY;
    },
    doesCompanyHaveTwoLevelApprovalThreshold: (state) => (companyId, thresholdType) => {
        const company = state.companies.value.filter(cmp => cmp.id === companyId)[0];
        if (!company) return false;
        const approvalRule = company.approvalRules.filter(rule => rule.type === thresholdType)[0];
        if (!approvalRule) return false;
        if (approvalRule.enabled) {
            return approvalRule.approvedBySecondary !== null && approvalRule.approvedByInitial !== null;
        }
        return false;
    }
};

export default getters;
