const colors = {
    lightGreen: [53, 146, 138],
    green: [82, 130, 23],
    blue: [53, 146, 138],
    lightBlue: [0, 112, 233],
    red: [210, 27, 2],
    lightGrey: [240, 240, 240],
    orange: [255, 241, 232]
};
export default colors;
