/* eslint-disable consistent-return */
import { getAuth0Instance } from './authWrapper';

const onlyOwnerViews = [
    '#/companies',
    '#/notification',
    '#/notification/overview',
    '#/notification/overview',
    '#/errors',
    '#/token',
    '#/errors/pis',
    '#/errors/server',
];

const authGuard = (to, from, next) => {
    const authService = getAuth0Instance();
    const checkAuthenticationAndRedirect = () => {
        // If the user is authenticated but tries to access /login, redirect to home
        if (!!to && !!to.name && to.name === 'login') {
            if (authService.isAuthenticated) {
                return next('/');
            }
            return next();
        }
        // Check if is owner to go to specific pages
        if (!!to && !!to.name && onlyOwnerViews.includes(to.name)) {
            if (authService.isOwner()) {
                return next();
            } else {
                return next('/404');
            }
        }
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            return next();
        }

        // If you don't want to use the login page, use the following line to redirect to Auth0 directly
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };

    // If loading has already finished, check our auth state using `checkAuthenticationAndRedirect()`
    if (!authService.loading) {
        return checkAuthenticationAndRedirect();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', (loading) => {
        if (loading === false) {
            return checkAuthenticationAndRedirect();
        }
    });
};

export default authGuard;
