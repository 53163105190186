<template>
  <b-row class="general-content">
    <b-col>
      <header>
        <p>{{ $t('admin.settings.content.profile.title') }}</p>
        <span>{{
            $t('admin.settings.content.profile.subtitle', { date: getDate() })
          }}</span>
      </header>
      <BlankLine :number-of-rows="2"/>
      <div class="modal-main">
        <b-row>
          <b-col class="col-9">
            <label>{{ $t('admin.settings.content.profile.profile_photo') }}</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-1">
            <img v-if="image" :src="image" alt="Profile">
            <img v-if="image === ''" :src="$auth.user.picture" alt="Profile">
          </b-col>
          <b-col class="col-10">
            <vue-anka-cropper
                :options="getCropperPropriety()"
                @cropper-saved="onSave"
                @cropper-file-selected="onSelection"
                @cropper-error="onError"
                @cropper-cancelled="handleOnReset"
            ></vue-anka-cropper>
          </b-col>
        </b-row>
        <BlankLine :number-of-rows="1"/>
        <b-row>
          <b-col class="col-6">
            <label for="full_name"> {{ $t('admin.settings.content.common.full_name') }} </label>
            <b-form-input id="full_name" v-model="name" type="text"/>
          </b-col>
          <b-col class="col-6">
            <label for="email"> {{ $t('admin.settings.content.common.email') }} </label>
            <b-form-input id="email" v-model="email" type="email"/>
          </b-col>
          <b-col class="col-6">
            <label for="platform"> {{ $t('admin.settings.content.common.platform') }} </label>
            <b-form-input id="platform" v-model="platform" disabled type="text"/>
          </b-col>
        </b-row>
        <BlankLine :number-of-rows="1"/>
        <b-row>
          <b-col>
            <p v-if="error" style="color: red;">{{ error }}</p>
          </b-col>
        </b-row>
      </div>
      <div class="setting-fixed-bottom">
        <b-button
            :disabled="isSaveButtonDisabled()"
            block
            v-bind:class="{'enable': !isSaveButtonDisabled(), 'disable':isSaveButtonDisabled()}"
            variant="primary"
            @click="handleOnClick">
          {{ $t('admin.settings.content.common.save') }}
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import vueAnkaCropper from 'vue-anka-cropper';
import getProperties from '@/components/Settings/cropper-properties';
import ApiUtils from '@/utils/ApiUtils';
import BlankLine from "@/components/Blank/BlankLine.vue";

export default {
    name: 'ProfileSettings',
    components: {BlankLine, vueAnkaCropper },
    mixins: [ApiUtils],
    props: {
        makeToast: {
            type: Function,
            default: () => []
        }
    },
    data() {
        return {
            id: '',
            name: '',
            email: '',
            platform: '',
            defaultName: '',
            defaultEmail: '',
            isNameChanged: false,
            isEmailChanged: false,
            file: null,
            image: '',
            appMetadata: {},
            error: null
        };
    },
    watch: {
        name: {
            handler(newName) {
                this.isNameChanged = newName !== this.defaultName;
            }
        },
        email: {
            handler(newMail) {
                this.isEmailChanged = newMail !== this.defaultEmail;
            }
        },
    },
    async mounted() {
        const appMetadataClaim = 'https://payday.works/app_metadata';
        const userRole = await this.$auth.getUserRoles();
        await this.getUserImage();
        this.name = userRole.data.name;
        this.id = userRole.data.sub;
        this.email = userRole.data.email;
        this.defaultName = userRole.data.name;
        this.defaultEmail = userRole.data.email;
        this.platform = 'N/A';
        if (userRole.data[appMetadataClaim]) {
            this.appMetadata = userRole.data[appMetadataClaim];
            if (this.appMetadata.apps) {
                if (this.appMetadata.apps.includes('ALL')) {
                    this.platform = 'ALL';
                } else {
                    const platforms = this.appMetadata.apps.filter(x => x !== 'ALL')
                        .reduce((acc, val) => `${acc}${val},`, '');
                    this.platform = this.removeLastInstanceOfComma(platforms);
                }
                if (this.platform === '') {
                    this.platform = 'N/A';
                }
            }
        }
    },
    methods: {
        getCropperPropriety() {
            return getProperties();
        },
        onError(errorMessage) {
            this.error = errorMessage;
            setTimeout(() => {
                this.error = null;
            }, 2500);
        },
        async onSelection(file) {
            this.file = file;
        },
        async onSave(croppedImage) {
            const {
                croppedFile,
                filename
            } = croppedImage;
            const imageAsFile = new File([croppedFile], filename.trim());
            let formData = new FormData();
            formData.append('image', imageAsFile);
            try {
                await this.setHeader();
                this.$api.updateAuthUserImage(formData)
                    .then(response => {
                        if (response.status === 200) {
                            this.makeToast(true, this.$t('admin.settings.common.user_update.successful'));
                            this.getUserImage();
                        }
                    });
            } catch (e) {
                this.makeToast(false, this.$t('admin.settings.common.user_update.failed'));
            }
        },
        async handleOnClick() {
            const user = {
                'name': this.name,
                'email': this.email,
                'app_metadata': this.appMetadata
            };
            try {
                await this.setHeader();
                await this.$api.updateAuthUser(this.id, user)
                    .then(response => {
                        if (response.status === 200) {
                            this.makeToast(true, this.$t('admin.settings.common.user_update.successful'));
                        }
                    });
            } catch (e) {
                this.makeToast(false, this.$t('admin.settings.common.user_update.failed'));
            }
        },
        async getUserImage() {
            try {
                await this.setHeader();
                this.image = await this.$api.getAuthUserImage();
                window.localStorage.setItem('thumbnail', this.image);
            } catch (e) {
                console.error(e);
            }
        },
        handleOnReset() {
            this.file = null;
        },
        isSaveButtonDisabled() {
            return !(this.isNameChanged || this.isEmailChanged);
        },
        getDate() {
            const date = new Date(this.$auth.user.updated_at).toLocaleDateString();
            const hour = new Date(this.$auth.user.updated_at).getHours();
            const minute = new Date(this.$auth.user.updated_at).getMinutes();
            const time = `${hour}:${minute}`;
            return `${date} - ${time}`;
        },
        removeLastInstanceOfComma(str) {
            const lastIndexOfL = str.lastIndexOf(',');
            return str.slice(0, lastIndexOfL) + str.slice(lastIndexOfL + 1);
        }
    }
};
</script>