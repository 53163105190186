<template>
  <div>
    <a :href="link" v-if="!isClickable">
      <b-img :alt="altImg"
             :class="className"
             :src="loadImage(imgName)"
      />
    </a>
    <div @click="handleOnClick" v-if="isClickable">
      <b-img :alt="altImg"
             :class="className"
             :src="loadImage(imgName)"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'ImageContainer',
    props: {
        isClickable: {
            type: Boolean,
            required: false,
            default: ()=> false
        },
        handleOnClick: {
            type: Function,
            required: false,
            default: ()=> {}
        },
        link: {
            type: String,
            required: false,
            default: () => '/'
        },
        className: {
            type: String,
            required: false,
            default: () => 'logo'
        },
        altImg: {
            type: String,
            required: true,
            default: () => 'PayDay Logo'
        },
        imgName: {
            type: String,
            required: true,
            default: () => 'logo_no_text.svg'
        },
    },
    methods: {
        loadImage(name) {
            return require(`@/assets/images/${name}`);
        },
    }
};
</script>

<style scoped>
img.logo {
  padding: 0;
  margin-top: 1rem;
  margin-left: 1rem;
  width: 3rem;
  height: 3rem;
}
.notification-bell-img {
  width: 70%;
  height: 70%;
  margin: 0.45rem;
}
</style>
