import ErrorMessages from '@/locales/en/error-messages.json';
import ErrorMessage from '@/store/models/errors/ErrorMessage';

/**
 * ApiError represents the errors coming from your API.
 * It should contain exactly the same fields as the ones you've defined in your backend.
 */
export default class ApiError extends Error {
    constructor(timestamp, errorCode, details) {
        super();
        this.name = 'ApiError';
        this.errorCode = errorCode;
        this.timestamp = timestamp;
        this.details = details;
    }

    /**
     * Returns an ErrorMessage object that contains a title and some details
     * Title and details are deduced using the file /locales/en/error_messages.json with the errorCode as a key
     * If the title and details cannot be found in this file, the default title and the content of the error message will be used instead
     */
    getUserFriendlyErrorMessage() {
        if (this.errorCode) {
            const errorMessage = ErrorMessages[this.errorCode];

            if (errorMessage) {
                return new ErrorMessage(errorMessage.title, errorMessage.details);
            }
        }
        const fallbackDetails = this.details ? this.details : ErrorMessages.default.details;
        return new ErrorMessage(ErrorMessages.default.title, fallbackDetails);
    }
}
