import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import AxiosFactory from '@/services/AxiosFactory';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

let instance;
const ROLES = {
    OWNER: 'ROLE_OWNER',
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER',
    VIEWER: 'ROLE_VIEWER'
};
/** Returns the current instance of the SDK */
export const getAuth0Instance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin,
    ...options
}) => {
    if (instance) return instance;

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null,
                userRoles: {
                    apps: [],
                    roles: [],
                    groups: [],
                    permissions: [],
                    envs: [],
                },
                indexUserRole : `${process.env.VUE_APP_AUTH0_DOMAIN}`,
            };
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            // Create a new instance of the SDK client using members of the given options object
            this.auth0Client = await createAuth0Client({
                domain: options.domain,
                client_id: options.clientId,
                audience: options.audience,
                redirect_uri: redirectUri,
            });
            try {
                // If the user is returning to the app after authentication...
                if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                    // handle the redirect and retrieve tokens
                    const {appState} = await this.auth0Client.handleRedirectCallback();
                    // Notify subscribers that the redirect callback has happened, passing the appState
                    // (useful for retrieving any pre-authentication state)
                    onRedirectCallback(appState);
                }
            } catch (e) {
                console.error(e);
                this.error = e;
            } finally {
                // Initialize our internal authentication state
                this.isAuthenticated = await this.auth0Client.isAuthenticated();
                this.user = await this.auth0Client.getUser();
                if (this.isAuthenticated) {
                    await this.getUserRoles().then(userRoles => {
                        this.userRoles = this.digestRoles(userRoles.data);
                    });
                }
                this.loading = false;
                // eslint-disable-next-line prefer-destructuring
                window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
            }
        },
        methods: {
            digestRoles(userDataResponse){
                return userDataResponse[this.indexUserRole];
            },
            /** Handles the callback when logging in using a redirect */
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    await this.auth0Client.handleRedirectCallback();
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = true;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                }
            },
            /** Authenticates the user using the redirect method */
            loginWithRedirect(o) {
                if (this.auth0Client) {
                    return this.auth0Client.loginWithRedirect(o);
                }
                return null;
            },
            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            getTokenSilently(o) {
                return this.auth0Client.getTokenSilently(o);
            },
            async getAuth0AxiosInstance() {
                const jwtToken = await this.getTokenSilently();
                const axiosInstance = new AxiosFactory(`${process.env.VUE_APP_AUTH0_DOMAIN_URL}`).getAxiosInstance();
                axiosInstance.https.defaults.headers.Authorization = `Bearer ${jwtToken}`;
                return axiosInstance;
            },
            async getUserRoles() {
                const {domainUrl} = this.auth0Client;
                const axiosInstance = await this.getAuth0AxiosInstance();
                return new Promise((resolve, reject) => {
                    axiosInstance.https.get(`${domainUrl}/userinfo`)
                        .then((res) => resolve(res))
                        .catch((error) => reject(error));
                });
            },
            /** Logs the user out and removes their session on the authorization server */
            logout(o) {
                return this.auth0Client.logout(o);
            },
            getUser() {
                return this.auth0Client.getUser();
            },
            isRolesDefined() {
                if (this.userRoles?.roles) {
                    return this.userRoles.roles.length > 0;
                }
                return false;
            },
            isOwner() {
                return this.isRolesDefined() ? this.hasRole(ROLES.OWNER) : false;
            },
            isAdmin() {
                return this.isRolesDefined() ? this.hasRole(ROLES.ADMIN) : false;
            },
            isUser() {
                return this.isRolesDefined() ? this.hasRole(ROLES.USER) : false;
            },
            getAppName(){
                if (this.hasApps()){
                    return this.userRoles.apps[0];
                }
                return '';
            },
            isAllAppUser(){
                return this.hasApp('ALL');
            },
            hasApps(){
                if(this.userRoles?.apps) {
                    return this.userRoles.apps.length > 0;
                }
                return false;
            },
            hasApp(appName) {
                return this.userRoles.apps.filter(app => app === appName).length > 0;
            },
            hasRole(roleName) {
                return this.userRoles.roles.filter(role => role === roleName).length > 0;
            },
            isUserEnabledToPerformOperations() {
                return this.isUser() || this.isAdmin() || this.isOwner();
            },
            getCompany() {
                if (this.userRoles?.apps) {
                    return this.userRoles.apps;
                }
                return [];
            },
        },
    });
    if (instance) {
        instance.$watch("loading", async loading => {
            if (!loading && instance.isAuthenticated) {
                const token = await instance.getTokenSilently();
                Vue.prototype.$api.setHeaderJwtToken(token);
            }
        });
    }
    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    // eslint-disable-next-line no-shadow
    install(Vue, options) {
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$auth = useAuth0(options);
    },
};
