import submittedImage from '@/assets/images/icon-verified-submitted.png';
import paidImage from '@/assets/images/icon-verified-paid.png';
import errorImage from '@/assets/images/icon-error.png';
import infoImage from '@/assets/images/icon-info-square.png';
import colors from '@/utils/ColorsConts';
import {TwoLevelApprovalStatus} from "@/utils/twoLevelApproval";
class PStatus {
    constructor(symbol, name, img, color, className) {
        this.symbol = symbol;
        this.name = name;
        this.img = img;
        this.color = color;
        this.className = className;
    }
}

const PaymentStatus = {
    STORED: new PStatus(
        'STORED',
        'admin.transactions.status-submitted',
        submittedImage,
        colors.lightGreen,
        'payment-status-submitted-container'
    ),
    PAYC_STORED: new PStatus(
        'PAYC_STORED',
        'admin.transactions.status-submitted',
        submittedImage,
        colors.lightGreen,
        'payment-status-submitted-container'
    ),
    PAY_STORED: new PStatus(
        'PAY_STORED',
        'admin.transactions.status-submitted',
        submittedImage,
        colors.lightGreen,
        'payment-status-submitted-container'
    ),
    PAYC_COMPLETED: new PStatus(
        'PAYC_COMPLETED',
        'admin.transactions.status-paid',
        paidImage,
        colors.green,
        'payment-status-paid-container'
    ),
    PAID: new PStatus(
        'PAID',
        'admin.transactions.status-paid',
        paidImage,
        colors.green,
        'payment-status-paid-container'
    ),
    PAYOUTAUTO: new PStatus(
        'PAYOUTAUTO',
        'admin.transactions.status-paid',
        paidImage,
        colors.green,
        'payment-status-paid-container'
    ),
    EXECUTED: new PStatus(
        'EXECUTED',
        'admin.transactions.status-paid',
        paidImage,
        colors.green,
        'payment-status-paid-container'
    ),
    PAY_COMPLETED: new PStatus(
        'PAY_COMPLETED',
        'admin.transactions.status-paid',
        paidImage,
        colors.green,
        'payment-status-paid-container'
    ),
    PAYC_ADJHOUR: new PStatus(
        'PAYC_ADJHOUR',
        'admin.transactions.status-hour-adj',
        submittedImage,
        colors.blue,
        'payment-status-submitted-container'
    ),
    PAYC_ADJNET: new PStatus(
        'PAYC_ADJNET',
        'admin.transactions.status-net-adj',
        submittedImage,
        colors.blue,
        'payment-status-submitted-container'
    ),
    TOBE_EXECUTED: new PStatus(
        'TOBE_EXECUTED',
        'admin.transactions.status-to-be-exec',
        infoImage,
        colors.lightBlue,
        'payment-status-toconfirm-container'
    ),
    EXECUTING: new PStatus(
        'EXECUTING',
        'admin.transactions.status-executing',
        paidImage,
        colors.blue,
        'payment-status-toconfirm-container'
    ),
    REJECTED: new PStatus(
        'REJECTED',
        'admin.transactions.status-rejected',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    ),
    INPROGRESS: new PStatus(
        'INPROGRESS',
        'admin.transactions.status-pending',
        submittedImage,
        colors.blue,
        'payment-status-toconfirm-container'
    ),
    TOCONFIRM: new PStatus(
        'TOCONFIRM',
        'admin.transactions.status-need-approval',
        infoImage,
        colors.lightBlue,
        'payment-status-toconfirm-container'
    ),
    ON_HOLD: new PStatus(
        'ON_HOLD',
        'admin.transactions.status-on-hold',
        infoImage,
        colors.lightBlue,
        'payment-status-toconfirm-container'
    ),
    REFUSED: new PStatus(
        'REFUSED',
        'admin.transactions.status-refused',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    ),
    FAILED: new PStatus(
        'FAILED',
        'admin.transactions.status-failed',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    ),
    SCHEDULED: new PStatus(
        'SCHEDULED',
        'admin.transactions.scheduled',
        infoImage,
        colors.lightBlue,
        'payment-status-toconfirm-container'
    ),
    PAYC_ADJNET_CANCELLED : new PStatus(
        'PAYC_ADJNET_CANCELLED',
        'admin.transactions.status-refused',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    ),
    TWO_LEVEL_APPROVAL : new PStatus(
        'TWO_LEVEL_APPROVAL',
        'admin.transactions.double-approval',
        null,
        colors.orange,
        'payment-status-double-approval-container'
    ),
    TWO_LEVEL_APPROVED_BY: new PStatus(
        'PAYC_STORED',
        'admin.transactions.status-approved-platform',
        paidImage,
        colors.green,
        'payment-status-paid-container'
    ),
    UNKNOWN: new PStatus(
        'UNKNOWN',
        'admin.invoice.status.unknown',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    ),
    REFUSED_BY: new PStatus(
        'REFUSED',
        'admin.transactions.refused-by-platform',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    )
};
export function getPaymentStatus(
    status,
    approvalStatus = TwoLevelApprovalStatus.NOT_APPROVED,
    isTwoLevelApprovalFlow = false) {

    if (isTwoLevelApprovalFlow)  {
        if (approvalStatus === TwoLevelApprovalStatus.NEED_APPROVAL) return PaymentStatus.TWO_LEVEL_APPROVAL;
        if (approvalStatus === TwoLevelApprovalStatus.APPROVED_BY_PLATFORM
            || approvalStatus === TwoLevelApprovalStatus.APPROVED_BY_PAYDAY ) {
            return PaymentStatus.TWO_LEVEL_APPROVED_BY;
        }
        if (approvalStatus === TwoLevelApprovalStatus.REFUSED_BY_PAYDAY
            || approvalStatus === TwoLevelApprovalStatus.REFUSED_BY_PLATFORM ) {
            return PaymentStatus.REFUSED_BY;
        }
    }
    if (PaymentStatus[status] !== undefined) return PaymentStatus[status];
    else return PaymentStatus["UNKNOWN"];
}
export default PaymentStatus;
