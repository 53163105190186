export const emptyExportData = {
    value: {},
    lastUpdated: null,
    isFetching: false,
    fetchEnded: false,
    exported: false,
    fileName: '',
    tableRef: null,
    format: '',
    t: ()=>{},
};
export const defaultStoreStatus = {
    locale: '',
    companies: {value: [], lastUpdated: null},
    transactionsToConfirm: {value: 0, lastUpdated: null},
    walTransactionsToConfirm: {value: 0, lastUpdated: null},
    pisTransactionsToConfirm: {value: 0, lastUpdated: null},
    workersToConfirm: {value: 0, lastUpdated: null},
    payrollsToConfirm: {value: 0, lastUpdated: null},
    invoicesToConfirm: {value: 0, lastUpdated: null},
    exporters: {
        workers: Object.assign({}, emptyExportData),
        walTransactions: Object.assign({}, emptyExportData),
        pisTransactions: Object.assign({}, emptyExportData),
        transactions: Object.assign({}, emptyExportData),
        payrolls: Object.assign({}, emptyExportData),
        invoices: Object.assign({}, emptyExportData),
        deposits: Object.assign({}, emptyExportData),
    },
};
