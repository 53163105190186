<template>
  <b-nav-item-dropdown
      right
      style="list-style: none"
      toggle-class="top-dropdown-link">
    <template slot="button-content">
      <b-row>
        <b-col style="width: 60%">
          <div v-if="roles.length > 0"><label class="role-name">{{ fixName(isRoles, roles[0]) }}</label></div>
          <div v-if="roles.length <= 0"><label class="role-name">{{ isRoles ? 'No Roles' : 'No Groups' }}</label></div>
        </b-col>
        <b-col class="align-container-right">
          <img alt="Arrow"
               class="user-dropdown-image"
               src="@/assets/images/dropdown-arrow.svg"
               style="width: 20px;height: 20px;">
        </b-col>

      </b-row>
    </template>
    <b-dropdown-item v-for="role in roles" :key="role">
      <label class="role-name" style="padding: 5px">{{ fixName(isRoles, role) }}</label>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<style>
.role-name {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #000740;
}
</style>
<script>
export default {
    name: 'RoleContainer',
    props: {
        roles: {
            type: Array,
            default: () => []
        },
        isRoles: {
            type: Boolean,
            default: () => true
        }
    },
    methods: {
        capitalFirstLetter(str) {
            return `${str.charAt(0)
                .toUpperCase()}${str.slice(1)
                .toLowerCase()}`;
        },
        fixName(isRoles, role) {
            return isRoles ?
                this.capitalFirstLetter(role.replace('ROLE_', '')) :
                this.capitalFirstLetter(role.replace('_GROUP', ''));
        }
    }
};
</script>
