<template>
  <div class="export-notification-item">
    <div class="export-notification-item-title">{{ $t('admin.notification.export.list.title') }}</div>
    <div class="export-notification-item-name">{{ exportFile.fileName }}</div>
    <div v-if="exportFile.isFetching" class="export-notification-item-progress">
      <b-progress :max="max" :value="100" animate animated striped></b-progress>
    </div>
    <div v-if="!exportFile.isFetching && exportFile.fetchEnded"
         aria-label="download"
         class="export-notification-item-download"
         role="button"
         tabindex="0"
         @click.prevent="$store.commit('exportFile',
         {
           file: exportFile,
           type
         })"
    >
      {{ $t('admin.notification.export.download') }}
    </div>
    <div class="export-notification-item-date">{{ new Date().toLocaleDateString('nl-NL') }}</div>
  </div>
</template>

<script>
export default {
    name: "NotificationListItem",
    props: {
        value: {
            type: Number,
            required: true,
            default: () => 0
        },
        name: {
            type: String,
            required: true,
            default: () => ("export-transactions-17-1-2023-17:05:15.pdf")
        },
        type: {
            type: String,
            required: true,
            default: () => ("worker")
        },
        exportFile: {
            type: Object,
            required: true,
            default: () => {
                return {
                    value: [],
                    lastUpdated: null,
                    totalPages: 0,
                    progress: 0,
                    percentage: 0,
                    isFetching: false,
                    fetchEnded: false,
                    exported: false,
                    fileName: ''
                };
            }
        }
    },
    data() {
        return {
            max: 100
        };
    }
};
</script>

<style scoped>
.export-notification-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  border: 1px solid #DEDEDE;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 1rem;
}

.export-notification-item-title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  color: #222222;
}

.export-notification-item-progress {
  width: 100%;
}

.export-notification-item-date {
  color: #808080;
}

.export-notification-item-download {
  color: #37ABA1;
}
</style>