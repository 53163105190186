<template>
  <b-navbar id="top-menu" class="top-nav" toggleable="lg">
    <b-navbar-nav class="ml-auto">
      <CashCard
          :company-balance="companyBalance"
          :should-show-balance-card="shouldShowBalanceCard()"
      />
      <NotificationBell
          :is-fetching="$store.getters.isFetchingForExport"
      />
      <b-nav-item-dropdown
          id="my-nav-dropdown"
          class="user-dropdown"
          left
          toggle-class="top-dropdown-link">
        <template slot="button-content">
          <div class="user-name-container no-padding">
            <b-row class="no-padding">
              <b-col class="profile-image" cols="3">
                <img v-if="!isAuthenticated || !auth" alt="Profile" src="@/assets/images/profile.svg">
                <img v-if="isAuthenticated" :src="image" alt="Profile">
              </b-col>
              <b-col class="user-name-content" cols="6">
                <b-row id="username" class="user-name">{{ userName }}</b-row>
                <b-row id="companies" class="user-company">{{ userCompany }}</b-row>
              </b-col>
              <b-col cols="1">
                <img alt="Arrow" class="user-dropdown-image" src="@/assets/images/dropdown-arrow.svg">
              </b-col>
            </b-row>
          </div>
        </template>
        <b-dropdown-item v-if="auth && !auth.isAuthenticated" id="login" @click="login">Login</b-dropdown-item>
        <b-dropdown-item v-if="auth && auth.isAuthenticated" id="logout" @click="logout">Logout
        </b-dropdown-item>
        <b-dropdown-item v-if="auth && auth.isAuthenticated" id="setting" @click="handleSettingVisibility">
          Setting
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <LanguagePicker/>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>

</template>
<script>
import LanguagePicker from '@/components/Language/LanguagePicker.vue';
import CashCard from '@/components/Layout/Menu/CashCard.vue';
import ApiUtils from '@/utils/ApiUtils.vue';
import NotificationBell from "@/components/Notification/NotificationBell.vue";

export default {
    name: 'TopMenu',
    mixins: [ApiUtils],
    components: {
        NotificationBell,
        LanguagePicker,
        CashCard,
    },
    data() {
        return {
            prg: 0.0
        };
    },
    props: {
        login: {
            type: Function,
            default: () => {
            }
        },
        logout: {
            type: Function,
            default: () => {
            }
        },
        handleSettingVisibility: {
            type: Function,
            default: () => {
            }
        },
        auth: {
            type: Object,
            default: () => {
            }
        },
        isAuthenticated: {
            type: Boolean,
            default: () => false
        },
        image: {
            type: String,
            default: () => {
            }
        },
        userName: {
            type: String,
            default: () => {
            }
        },
        userCompany: {
            type: String,
            default: () => {
            }
        },
        companyBalance: {
            type: Object,
            default: () => {
                return {
                    availableBalance: 0,
                    daysLeft: '0',
                    bankBalance: 0,
                    companyId: -1,
                    companyName: 'N/A',
                    dailyAveragePayout: 1.0,
                    walletBalance: 1.0,
                };
            }
        },
    },
    methods: {
        shouldShowBalanceCard() {
            return this.$auth.isAdmin();
        },
    },
    watch: {
        '$store.state.exporters.getters.isFetchingForExport': function () {
            if (this.$store.state.exporters.getGlobalProgress >= 0) {
                this.prg = parseInt(this.$store.state.exporters.getGlobalProgress) || 0;
            }
        }
    }

};
</script>

<style scoped>
.top-nav {
  background-color: white;
  box-shadow: 0 2px 2px rgb(34 34 34/8%);
  margin: 0;
  padding: 0;
}

.contact > a.nav-link {
  color: #000740;
  font-weight: bold;
}


.user-name-content > div {
  color: #000740;
}
</style>
