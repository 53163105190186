<template>
  <div></div>
</template>

<script>
export default {
    name: 'FormatUtils',
    data() {
        return {
            months: [
                {
                    en: 'January',
                    nl: 'Januari'
                },
                {
                    en: 'February',
                    nl: 'Februari'
                },
                {
                    en: 'March',
                    nl: 'Maart'
                },
                {
                    en: 'April',
                    nl: 'April'
                },
                {
                    en: 'May',
                    nl: 'Kunnen'
                },
                {
                    en: 'June',
                    nl: 'Juni'
                },
                {
                    en: 'July',
                    nl: 'Juli'
                },
                {
                    en: 'August',
                    nl: 'Augustus'
                },
                {
                    en: 'September',
                    nl: 'September'
                },
                {
                    en: 'October',
                    nl: 'Oktober'
                },
                {
                    en: 'November',
                    nl: 'November'
                },
                {
                    en: 'December',
                    nl: 'December'
                },
            ],
        };
    },
    methods: {
        getAmountForExporter(amount) {
            const value = parseFloat(amount !== null ? amount : '0');
            return `${value.toFixed(2)
                .toString()}`;
        },
        getAmountInUsFormat(amount) {
            const value = parseFloat(amount);
            const amountValue = parseFloat(value.toFixed(2));
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'EUR',
            });
            return formatter.format(amountValue);
        },
        getAmount(amount) {
            const value = parseFloat(amount !== null ? amount : 0);
            switch (true) {
            case (value < 0):
                return `- € ${value.toFixed(2)
                    .toString()
                    .replace('-', '')
                    .replace('.', ',')}`;
            case (value === 0) :
                return `€ ${0.00.toFixed(2)
                    .toString()
                    .replace('.', ',')}`;
            case (value > 0):
                return `€ ${value.toFixed(2)
                    .toString()
                    .replace('.', ',')}`;
            }
        },
        getHours(startTime, endTime) {
            const start = `${new Date(startTime).getHours()}:${this.getMinute(new Date(startTime).getMinutes())}`;
            const end = `${new Date(endTime).getHours()}:${this.getMinute(new Date(endTime).getMinutes())}`;
            return `${start}-${end}`;
        },
        getMinute(minute) {
            if (minute < 10) {
                return `0${minute}`;
            }
            return minute;
        },
        getHourAdj(shiftDetail) {
            if (shiftDetail.paycAdjustments && shiftDetail.paycAdjustments.length > 0) {
                return this.getHours(shiftDetail.paycAdjustments[0].approvedStartTime, shiftDetail.paycAdjustments[0].approvedEndTime);
            }
            return 'N/A';
        },
        getFormattedDate() {
            return new Date().toLocaleString('nl-NL');
        },
        getRightMonthLocalized(monthNumber) {
            const month = this.months[monthNumber];
            if (this.$i18n.locale === 'en') return month.en;
            return month.nl;
        },
        formatDate(date) {
            if (!date) return '-';
            const currentDate = new Date(date);
            return `${currentDate.getDate()} ${this.getRightMonthLocalized(currentDate.getMonth())}`;
        },
        convertDateFormat(date) {
            const month = date.getMonth() <= 11 ? date.getMonth() + 1 : 1;
            const twoDigitMonth = month
                .toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                });
            const twoDigitDate = date.getDate()
                .toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                });
            return `${date.getFullYear()}-${twoDigitMonth}-${twoDigitDate}T00:00:00`;
        },
        getDaysInMonth(month, year) {
            // Returns the last day of the month based on the year (cover also leap year)
            // Be careful uses Bitwise operator
            return month === 2 ? year & 3 || !(year % 25) && year && 15 ? 28 : 29 : 30 + (month + (month >> 3) & 1);
        },
        getCurrentYear() {
            return new Date().getFullYear();
        },
        getCurrentMonth() {
            return new Date().getMonth();
        },
        capitalizeOnlyFirstLetter(string) {
            if (string) return string?.charAt(0).toUpperCase() + string.slice(1);
            return string;
        },
        getValueIfDefined(value) {
            if (value) {
                return value;
            } else {
                return 'No Value Defined';
            }
        },
    }
};
</script>
