<template>
  <div></div>
</template>

<script>
import PaymentStatus from "@/utils/PaymentStatus";
import WorkerStatus from "@/utils/WorkerStatus";
import constants from "@/utils/constants";
import PayrollStatus from "@/utils/PayrollStatus";

export default {
    name: 'ApiUtils',
    data() {
        return {
            allCompanies: [],
            allCompaniesPis: [],
            allCompaniesWal: [],
            allCompaniesWalc: [],
            allCompaniesWalcAndPis: [],
            allToConfirmTransactions: 0,
            allToConfirmWalTransactions: 0,
            allToConfirmPisTransactions: 0,
            allToConfirmWorkers: 0,
            allPendingPayrolls: 0,
            sortBy: "creationDate",
            orderBy: "DESC",
            firstPage: 0,
            elementsPerPage: 100
        };
    },
    mounted() {
        this.getCompanies();
    },
    methods: {
        async setHeader() {
            try {
                if (this.$auth) {
                    const token = await this.$auth.getTokenSilently();
                    this.$api.setHeaderJwtToken(token);
                }
            } catch (error) {
                if (error.error !== 'login_required') {
                    this.$auth.logout({
                        returnTo: window.location.origin,
                    });
                }
            }
        },
        async getCompanies() {
            if (this.$auth) {
                if (!this.isTimeElapsed(this.$store.getters.companiesLastUpdated, new Date())) {
                    this.allCompanies = this.sortCompaniesByName(this.mapCompanies(this.$store.getters.getAll));
                    return this.allCompanies;
                }
                try {
                    await this.setHeader();
                    const companiesResponse = await this.$api.getCompanies();
                    const response = companiesResponse.data;
                    this.$store.commit('addCompanies', response);
                    this.allCompanies = this.sortCompaniesByName(this.mapCompanies(response));
                    return this.allCompanies;
                } catch (e) {
                    this.allCompanies = [];
                }
            }
        },
        async getAllPisCompanies() {
            return await this.sortCompaniesByName(this.mapCompanies(this.$store.getters.getPis));
        },
        async getAllWalCompanies() {
            return await this.sortCompaniesByName(this.mapCompanies(this.$store.getters.getWal));
        },
        async getAllWalcCompanies() {
            return await this.sortCompaniesByName(this.mapCompanies(this.$store.getters.getWalc));
        },
        async getAllWalcAndPisCompanies() {
            return await this.sortCompaniesByName(this.mapCompanies(this.$store.getters.getWalcAndPis));
        },
        async forceCompanyDataUpdate() {
            if (this.$auth) {
                try {
                    await this.setHeader();
                    const companiesResponse = await this.$api.getCompanies();
                    const response = companiesResponse.data;
                    this.$store.commit('addCompanies', response);
                    this.allCompanies = this.sortCompaniesByName(this.mapCompanies(response));
                    return this.allCompanies;
                } catch (e) {
                    this.allCompanies = [];
                }
            }
        },
        async getToConfirmTransactions() {
            if (this.$auth) {
                if (!this.isTimeElapsed(this.$store.getters.transactionsLastUpdated, new Date())) {
                    this.allToConfirmTransactions = this.$store.getters.getAllTransactions;
                    return this.allToConfirmTransactions;
                }
                try {
                    const transactionResponse = await this.$api.getMainTransactions(false,
                        this.firstPage, this.elementsPerPage, this.sortBy, this.orderBy,
                        [], [], [PaymentStatus.TOCONFIRM.symbol],
                        "", "", "",
                        "", "");
                    const response = transactionResponse.data.transactions;
                    this.$store.commit('setTransaction', {
                        value: response.length,
                        lastUpdated: new Date()
                    });
                    this.allToConfirmTransactions = response.length;
                    return this.allToConfirmTransactions;
                } catch (e) {
                    this.allToConfirmTransactions = 0;
                    return 0;
                }
            }
            return this.allToConfirmTransactions;
        },
        async getToConfirmPisTransactions() {
            if (this.$auth) {
                if (!this.isTimeElapsed(this.$store.getters.pisTransactionsLastUpdated, new Date())) {
                    this.allToConfirmPisTransactions = this.$store.getters.getAllPisTransactions;
                    return this.allToConfirmPisTransactions;
                }
                try {
                    const transactionResponse = await this.$api.getPisTransactions(
                        this.firstPage, this.elementsPerPage, this.sortBy, this.orderBy,
                        [], [], [PaymentStatus.TOCONFIRM.symbol],
                        "", "", "",
                        "", "");
                    const response = transactionResponse.data.transactions;
                    this.$store.commit('setPisTransaction', {
                        value: response.length,
                        lastUpdated: new Date()
                    });
                    this.allToConfirmPisTransactions = response.length;
                    return this.allToConfirmPisTransactions;
                } catch (e) {
                    this.allToConfirmPisTransactions = 0;
                    return 0;
                }
            }
            return this.allToConfirmPisTransactions;
        },
        async getToConfirmWalTransactions() {
            if (this.$auth) {
                if (!this.isTimeElapsed(this.$store.getters.walTransactionsLastUpdated, new Date())) {
                    this.allToConfirmWalTransactions = this.$store.getters.getAllWalTransactions;
                    return this.allToConfirmWalTransactions;
                }
                try {
                    const transactionResponse = await this.$api.getMainTransactions(
                        true,
                        this.firstPage, this.elementsPerPage, this.sortBy, this.orderBy,
                        [], [], [PaymentStatus.TOCONFIRM.symbol],
                        "", "", "",
                        "", "");
                    const response = transactionResponse.data.transactions;
                    this.$store.commit('setWalTransaction', {
                        value: response.length,
                        lastUpdated: new Date()
                    });
                    this.allToConfirmWalTransactions = response.length;
                    return this.allToConfirmWalTransactions;
                } catch (e) {
                    this.allToConfirmWalTransactions = 0;
                    return 0;
                }
            }
            return this.allToConfirmWalTransactions;
        },
        isTimeElapsed(startTime, now) {
            if (!startTime || !now) return true;
            let timeDiff = now - startTime; //in ms
            // strip the ms
            timeDiff /= 1000;
            return Math.round(timeDiff) > constants.REFRESH_INTERVAL_S;
        },
        async getToConfirmWorkers() {
            if (this.$auth) {
                if (!this.isTimeElapsed(this.$store.getters.workerLastUpdated, new Date())) {
                    this.allToConfirmWorkers = this.$store.getters.getAllWorkers;
                    return this.allToConfirmWorkers;
                }
                try {
                    await this.$api.getUserData(this.firstPage, this.elementsPerPage, this.sortBy, this.orderBy, false, [], [],
                        [WorkerStatus.NEED_APPROVAL.symbol, WorkerStatus.PAYMENT_ON_HOLD.symbol], "", "")
                        .then(transactionResponse => {
                            const response = transactionResponse.data.workers;
                            this.$store.commit('setWorker', {
                                value: response.length,
                                lastUpdated: new Date()
                            });
                            this.allToConfirmWorkers = response.length;
                            return this.allToConfirmWorkers;
                        });
                } catch (e) {
                    this.allToConfirmWorkers = 0;
                    return 0;
                }
            }
            return this.allToConfirmWorkers;
        },
        async getPendingPayrolls() {
            if (this.$auth) {
                if (!this.isTimeElapsed(this.$store.getters.payrollsLastUpdated, new Date())) {
                    this.allPendingPayrolls = await this.$store.getters.getAllPayrolls;
                    return this.allPendingPayrolls;
                }
                try {
                    await this.$api.getPayrollsData(
                        this.firstPage, this.elementsPerPage, this.sortBy, this.orderBy,
                        '', '', [], [PayrollStatus.PENDING.symbol], '')
                        .then(payrollResponse => {
                            const response = payrollResponse.data.payrolls;
                            this.$store.commit('setPayroll', {
                                value: response.length,
                                lastUpdated: new Date()
                            });
                            this.allPendingPayrolls = response.length;
                            return this.allPendingPayrolls;
                        });
                } catch (e) {
                    this.allPendingPayrolls = 0;
                    return 0;
                }
            }
            return this.allPendingPayrolls;
        },
        mapCompanies(companies) {
            return companies.map(company => ({
                text: company.displayName,
                name: company.displayName,
                value: company.id,
                walletType: company.walletType
            }));
        },
        sortCompaniesByName(companies) {
            return companies.sort((firstName, secondName) => {
                const firstNameUpper = firstName.name.toUpperCase();
                const secondNameUpper = secondName.name.toUpperCase();
                if (firstNameUpper < secondNameUpper) return -1;
                if (firstNameUpper > secondNameUpper) return 1;
                return 0;
            });
        },
        updateTable(ref) {
            if (ref && ref.table) {
                ref.table.refresh();
            }
        },
        loadImage(name) {
            return require(`@/assets/images/${name}`);
        },
        getCompanyName(id) {
            return this.$store.getters.getCompanyName(id);
        }
    }
};
</script>
