<template>
  <div class="empty-line-container" v-html="lines"></div>
</template>

<script>

export default {
    name: 'BlankLine',
    props: {
        numberOfRows: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        lines() {
            const rows = Array.apply(null, Array(this.numberOfRows))
                .map(() => `<b-row> <b-col className='col-12'>&nbsp;</b-col> </b-row>`)
                .reduce((acc, val) => acc + val);
            return rows;
        }
    }
};
</script>
<style scoped>
.empty-line-container {
  display: flex;
  flex-direction: column;
}
</style>

