<template>
  <div id="app">
    <!--    <b-row>-->
    <BaseNavbar
        :payrolls-pending="payrollsPending"
        :pis-transactions-to-confirm="pisTransactionsToConfirm"
        :total-errors="errorsCount.totalErrors"
        :transactions-to-confirm="transactionsToConfirm"
        :wal-transactions-to-confirm="walTransactionsToConfirm"
        :workers-to-confirm="workersToConfirm"
        class="sidebar"
    />
    <div id="page-container">
      <TopMenu
          :auth="$auth"
          :company-balance="companyBalance"
          :handle-setting-visibility="handleSettingVisibility"
          :image="image"
          :is-authenticated="isAuthenticated"
          :login="login"
          :logout="logout"
          :userCompany="userCompany"
          :userName="userName"
      />
      <div id="content" :key="userName + isAuthenticated">
        <Settings v-if="isSettingVisible" :handle-close-button="handleSettingVisibility"/>
        <router-view/>
      </div>
      <PayDayFooter/>
    </div>
    <div v-if="updateExists" class="update-pwa">
      <p>New Update Available</p>
      <MainButton :on-click="refreshApp" buttonText="Click here to update"/>
    </div>
  </div>

</template>

<script>
import BaseNavbar from '@/components/Layout/BaseNavbar.vue';
import PwaUpdateUtils from '@/utils/PwaUpdateUtils.vue';
import MainButton from '@/components/Buttons/MainButton.vue';
import Settings from '@/views/overlay/Settings.vue';
import PayDayFooter from '@/components/Layout/Footer/PayDayFooter.vue';
import TopMenu from '@/components/Layout/Menu/TopMenu.vue';
import ApiUtils from '@/utils/ApiUtils';
import constants from "@/utils/constants";

export default {
    name: 'App',
    components: {
        TopMenu,
        BaseNavbar,
        Settings,
        PayDayFooter,
        MainButton,
    },
    data() {
        return {
            isSettingVisible: false,
            image: '',
            errorsCount: {
                totalErrors: 0,
                serverErrors: 0,
                pisErrors: 0,
            },
            companyBalance: {
                availableBalance: 0,
                daysLeft: '0',
                bankBalance: 0,
                companyId: -1,
                companyName: 'N/A',
                dailyAveragePayout: 1.0,
                walletBalance: 1.0,
            },
            transactionsToConfirm: 0,
            pisTransactionsToConfirm: 0,
            walTransactionsToConfirm: 0,
            workersToConfirm: 0,
            payrollsPending: 0,
            alC: []
        };
    },
    mixins: [PwaUpdateUtils, ApiUtils],
    computed: {
        userName() {
            if (this.$auth?.user) {
                return this.$auth.user.name;
            }
            return '';

        },
        // eslint-disable-next-line vue/return-in-computed-property
        isAuthenticated() {
            if (this.$auth) {
                return this.$auth.isAuthenticated;
            }
        },
        userCompany() {
            return this.$auth.getAppName();
        },
    },
    mounted() {
        const refreshBadges = () => {
            setInterval(async () => {
                await this.fetchNotificationForTransactions();
                await this.fetchNotificationForWorkers();
                await this.fetchNotificationForPayrolls();
            }, constants.REFRESH_INTERVAL_MS);
        };
        refreshBadges();
    },
    methods: {
        shouldShowBalanceCard() {
            return this.$auth.isAdmin();
        },
        handleSettingVisibility() {
            this.isSettingVisible = !this.isSettingVisible;
        },
        login() {
            if (this.$auth && !this.$auth.isAuthenticated) {
                this.$auth.loginWithRedirect();
            }
        },
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
        async loadErrorCount() {
            if (this.$auth.isAuthenticated && this.$auth.isOwner()) {
                await this.setHeader();
                await this.$api.getErrorCount()
                    .then(response => {
                        if (response.data) {
                            this.errorsCount = response.data;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },
        async loadCompanyBalance() {
            if (this.shouldShowBalanceCard) {
                await this.setHeader();
                await this.$api.getCompanyBalance()
                    .then(response => {
                        if (response.data) {
                            switch (true) {
                            case (response.data.length === 1):
                            case (response.data.length > 1):
                                this.companyBalance = response.data[0];
                                break;
                            case (response.data.length <= 0):
                                console.error('No data received for company balance');
                                break;
                            }
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },
        async getUserImage() {
            try {
                await this.setHeader();
                const fetchedImage = await this.$api.getAuthUserImage();
                if (fetchedImage) {
                    this.image = fetchedImage;
                } else {
                    this.image = this.$auth.user.picture;
                }
                window.localStorage.setItem('thumbnail', this.image);
                this.image = window.localStorage.getItem('thumbnail');
            } catch (e) {
                console.error(e);
                this.image = window.localStorage.getItem('thumbnail');
            }
        },
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                this.$store.commit('setLocale', locale);
            }
        },
        async fetchNotificationForTransactions() {
            this.getToConfirmWalTransactions().then(response => {
                this.walTransactionsToConfirm = response;
            });
            this.alC = await this.getCompanies();
            this.getToConfirmTransactions().then(response => {
                this.transactionsToConfirm = response;
            });
            this.getToConfirmPisTransactions().then(response => {
                this.pisTransactionsToConfirm = response;
            });
        },
        async fetchNotificationForWorkers() {
            this.getToConfirmWorkers().then(response => {
                this.workersToConfirm = response;
            });
        },
        async fetchNotificationForPayrolls() {
            this.getPendingPayrolls().then(response => {
                this.payrollsPending = response;
            });
        },
        async wipeNotifications() {
            this.$store.commit('wipeTransaction');
            this.$store.commit('wipeWalTransaction');
            this.$store.commit('wipePisTransaction');
            this.$store.commit('wipeWorker');
            this.$store.commit('wipePayroll');
        }
    },
    watch: {
        async isAuthenticated(newAuthValue) {
            const thumbnail = window.localStorage.getItem('thumbnail');
            if (thumbnail) {
                this.image = thumbnail;
            } else {
                await this.getUserImage();
            }
            if (newAuthValue) {
                if (!this.$store.getters.isLocaleSet) {
                    if (this.$auth.isAllAppUser()) {
                        this.switchLocale('en');
                    } else {
                        this.switchLocale('nl_NL');
                    }
                }
                await this.wipeNotifications();
                await this.loadCompanyBalance();
                await this.loadErrorCount();
                await this.fetchNotificationForTransactions();
                await this.fetchNotificationForWorkers();
                await this.fetchNotificationForPayrolls();

            }
        },
        $route: {
            immediate: true,
            handler(to) {
                document.title = 'Payday Admin Panel';
                if (to?.meta?.title) {
                    document.title = to.meta.title;
                }
            }
        }
    },
    created() {
        this.$store.commit('wipeAllProgress');
    }
};
</script>
<style scoped>
#app {
  height: 100vh;
  width: 100%;
}

.update-pwa {
  position: fixed; /* or absolute */
  top: 0;
  left: 50%;
  text-align: center;
  vertical-align: middle;
  padding: 25px;
  border-radius: 5px;
  transform: translate(-35%, -2%);
  background-color: white;
}

.main-container {
  margin: 0;
  padding: 0;
  min-width: 100%;
}

.sidebar {
  min-width: 5rem;
  max-width: 5rem;
  padding-left: 0;
  padding-right: 0;
  background-color: #000740;
}

.main-content {
  padding: 0;
  margin: 0;
}

#page-container {
  margin-left: 80px;
  height: calc(100vh - 50px);
}
</style>
