<template>
  <b-button block :variant="variant" @click="onClick" :disabled="!isActive">
    {{ buttonText }}
  </b-button>
</template>

<script>
export default {
    name: 'MainButton',
    props: {
        onClick: {
            type: Function,
            default: () => {
            }
        },
        buttonText: {
            type: String,
            default: () => this.$t('admin.transactions.detail.approve-payment')
        },
        operation: {
            type: String,
            default: () => 'sidebar-refuse'
        },
        isActive: {
            type: Boolean,
            default: () => true
        },
        variant: {
            type: String,
            default: () => 'primary'
        }
    }
};
</script>

<style scoped>
button.btn.btn-primary.disabled {
  background-color: #CCCCCC;
  border-color: #CCCCCC;
}
</style>
