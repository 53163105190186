<template>
  <div id="main-menu">
    <ImageContainer alt-img="Logo" img-name="logo_no_text.svg"/>
    <MainMenu
        :has-route="hasRoute"
        :nav-items="navItems"
    />
  </div>
</template>

<script>
import ApiUtils from '@/utils/ApiUtils.vue';
import MainMenu from '@/components/Layout/Menu/MainMenu.vue';
import ImageContainer from '@/components/Layout/Img/ImageContainer.vue';
import {PIS_WALLET_TYPE, WAL_WALLET_TYPE, WALC_WALLET_TYPE} from '@/store/store.js';

export default {
    name: 'BaseNavbar',
    components: {
        ImageContainer,
        MainMenu,
    },
    mixins: [ApiUtils],
    data() {
        return {
            titlePage: process.env.VUE_APP_NAME.replace('-', ' ')
                .replace('-', ' '),
            image: '',
            windowHeight: 0,
            version: 0,
            basiTopMargin: 92,
            noNotification: []
        };
    },
    props: {
        totalErrors: {
            type: Number,
            required: false,
            default: () => -1
        },
        walTransactionsToConfirm: {
            type: Number,
            required: true,
            default: () => 0
        },
        pisTransactionsToConfirm: {
            type: Number,
            required: true,
            default: () => 0
        },
        transactionsToConfirm: {
            type: Number,
            required: true,
            default: () => 0
        },
        workersToConfirm: {
            type: Number,
            required: true,
            default: () => 0
        },
        payrollsPending: {
            type: Number,
            required: true,
            default: () => 0
        },
    },
    async mounted() {
        const thumbnail = window.localStorage.getItem('thumbnail');
        if (thumbnail) {
            this.image = thumbnail;
        }
        this.windowHeight = window.innerHeight - this.basiTopMargin;
        window.addEventListener('resize', () => {
            this.windowHeight = window.innerHeight;
        });
    },
    computed: {
        navItems() {
            return [
                {
                    title: this.$t('admin.transactions.transaction-detail.worker'),
                    url: '#/workers/active',
                    icon: this.loadImage('menu-workers-icon-active.svg'),
                    iconActive: this.loadImage('menu-workers-icon.svg'),
                    disabled: false,
                    hasNotifications: this.hasNotifications(this.workersToConfirm),
                    notificationNumber: this.getNotificationSize(this.workersToConfirm)
                },
                {
                    title: this.$t('admin.menu.transaction.transaction'),
                    url: this.isWalViewer() ? '#/transactions/wal' : this.isPisViewer() ? '#/transactions/pis' : '#/transactions' ,
                    disabled: false,
                    iconActive: this.loadImage('menu-transactions-icon.svg'),
                    icon: this.loadImage('menu-transactions-icon-active.svg'),
                    hasNotifications: (this.hasNotifications(this.transactionsToConfirm) || this.hasNotifications(this.walTransactionsToConfirm) || this.hasNotifications(this.pisTransactionsToConfirm)),
                    notificationNumber: this.getNotificationSize(this.transactionsToConfirm) + this.getNotificationSize(this.walTransactionsToConfirm) + this.getNotificationSize(this.pisTransactionsToConfirm),
                    submenu: [
                        {
                            title: `${this.$t('admin.menu.transaction.transaction')} ZZP`,
                            url: '#/transactions/wal',
                            disabled: !(this.$auth.isOwner() || this.isWalViewer()),
                            hasNotifications: this.hasNotifications(this.walTransactionsToConfirm),
                            notificationNumber: this.getNotificationSize(this.walTransactionsToConfirm)
                        },
                        {
                            title: `${this.$t('admin.menu.transaction.transaction')} TEMP`,
                            url: '#/transactions',
                            disabled: !(this.$auth.isOwner() || this.isWalcViewer()),
                            hasNotifications: this.hasNotifications(this.transactionsToConfirm),
                            notificationNumber: this.getNotificationSize(this.transactionsToConfirm),
                        },
                        {
                            title: `${this.$t('admin.menu.transaction.transaction')} PIS`,
                            url: '#/transactions/pis',
                            disabled: !(this.$auth.isOwner() || this.isPisViewer()),
                            hasNotifications: this.hasNotifications(this.pisTransactionsToConfirm),
                            notificationNumber: this.getNotificationSize(this.pisTransactionsToConfirm),
                        },
                    ],
                },
                {
                    title: this.$t('admin.menu.payslip.payslip'),
                    url: '#/payroll',
                    disabled: !(this.$auth.isOwner() || this.isWalcViewer() || this.isPisViewer()),
                    icon: this.loadImage('ic-payroll-active.svg'),
                    iconActive: this.loadImage('ic-payroll.svg'),
                    hasNotifications: this.hasNotifications(this.payrollsPending),
                    notificationNumber: this.getNotificationSize(this.payrollsPending)
                },
                {
                    title: this.$t('admin.menu.invoice.invoice'),
                    url: '#/invoice',
                    disabled: !(this.isWalViewer() || this.$auth.isOwner()),
                    icon: this.loadImage('menu-payrolls-icon-active.svg'),
                    iconActive: this.loadImage('menu-payrolls-icon.svg'),
                    hasNotifications: this.hasNotifications(this.noNotification),
                    notificationNumber: this.noNotification.length
                },
                {
                    title: this.$t('admin.menu.balance.balance'),
                    url: '#/balance',
                    disabled: false,
                    icon: this.loadImage('menu-deposits-icon-active.svg'),
                    iconActive: this.loadImage('menu-deposits-icon.svg'),
                    hasNotifications: this.hasNotifications(this.noNotification),
                    notificationNumber: this.noNotification.length
                },
                {
                    title: `Error log (${this.totalErrors})`,
                    url: '#/errors/server',
                    disabled: !this.$auth.isOwner(),
                    icon: this.loadImage('menu-errors-icon-active.svg'),
                    iconActive: this.loadImage('menu-errors-icon.svg'),
                    hasNotifications: this.hasNotifications(this.noNotification),
                    notificationNumber: this.noNotification.length
                },
                {
                    title: `${this.$t('admin.menu.notification.title')}`,
                    url: '#/notification/overview',
                    disabled: !this.$auth.isOwner(),
                    icon: this.loadImage('menu-notification-icon-active.svg'),
                    iconActive: this.loadImage('menu-notification-icon.svg'),
                    hasNotifications: this.hasNotifications(this.noNotification),
                    notificationNumber: this.noNotification.length,
                    submenu: [
                        {
                            title: `${this.$t('admin.menu.notification.overview')}`,
                            url: '#/notification/overview',
                            hasNotifications: this.hasNotifications(this.noNotification),
                            notificationNumber: this.noNotification.length
                        },
                        {
                            title: `${this.$t('admin.menu.notification.setting')}`,
                            url: '#/notification/settings',
                            hasNotifications: this.hasNotifications(this.noNotification),
                            notificationNumber: this.noNotification.length
                        },
                    ],
                },
                {
                    title: `${this.$t('admin.common.companies')}`,
                    url: '#/companies',
                    disabled: !this.$auth.isOwner(),
                    icon: this.loadImage('ic_companies-active.svg'),
                    iconActive: this.loadImage('ic_companies.svg'),
                    hasNotifications: this.hasNotifications(this.noNotification),
                    notificationNumber: this.noNotification.length
                },
                {
                    title: `${this.$t('admin.common.token.title')}`,
                    url: '#/token',
                    disabled: !this.$auth.isOwner() || process.env.VUE_APP_ENV !== 'development',
                    icon: this.loadImage('ic_token.svg'),
                    iconActive: this.loadImage('ic_token-active.svg'),
                    hasNotifications: this.hasNotifications(this.noNotification),
                    notificationNumber: this.noNotification.length
                }
            ];
        },
        userName() {
            return this.$auth.user?.nickname;
        },
        userCompany() {
            return this.$auth.getAppName();
        },
        isAuthenticated() {
            return this.$auth?.isAuthenticated;
        },
        hasAllRole() {
            return this.$auth.isOwner() && this.$auth.isAllAppUser();
        },
    },
    methods: {
        getWalletTypeByAppRoles() {
            return this.$store
                .getters
                .getWalletTypeByCompanyName(this.$auth.getAppName());
        },
        isPisViewer() {
            return this.getWalletTypeByAppRoles() === PIS_WALLET_TYPE;
        },
        isWalViewer() {
            return this.getWalletTypeByAppRoles() === WAL_WALLET_TYPE;
        },
        isWalcViewer() {
            return this.getWalletTypeByAppRoles() === WALC_WALLET_TYPE;
        },
        login() {
            if (this.$auth && !this.$auth.isAuthenticated) {
                this.$auth.loginWithRedirect();
            }
        },
        hasRoute(item) {
            if (!this.$route) return false;
            const routeName = this.$route.name;
            if (item.submenu) {
                return item.submenu.some((a) => routeName && routeName === a.url);
            }
            return routeName && routeName === item.url;
        },
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
        hasNotifications(notifications) {
            return Array.isArray(notifications) ? notifications.length > 0 : notifications;
        },
        getNotificationSize(notifications) {
            return Array.isArray(notifications) ? notifications.length : notifications;
        },

    },
};
</script>
<style scoped>
#main-menu {
  height: 100vh;
  width: 80px; /* adjust to your desired width */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>
