/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import ErrorMessages from '@/locales/en/error-messages.json';
import ErrorMessage from '@/store/models/errors/ErrorMessage';
import BaseAlertModel from '@/store/models/BaseAlertModel';
import VARIANT from '@/store/enums/variant-enum';

/**
 * Add a method getUserFriendlyErrorMessage() to the generic Javascript Error
 * This method return an ErrorMessage object that contains
 * - a default title : the default title is defined in the file /locales/en/error_messages.json
 * - the content of the error message
 */
export default {
    install() {
        Error.prototype.getUserFriendlyErrorMessage = function () {
            return new ErrorMessage(ErrorMessages.default.title, ErrorMessages.default.details);
        };

        Error.prototype.getBaseAlert = function () {
            const errorMessage = this.getUserFriendlyErrorMessage();
            return new BaseAlertModel(errorMessage.title, errorMessage.details, VARIANT.DANGER);
        };
    },
};
