<template>
  <b-row class="general-content">
    <b-col>
      <header>
        <p>{{ $t('admin.settings.content.password.title') }}</p>
      </header>
      <BlankLine :number-of-rows="1"/>
      <div class="modal-main">
        <b-row>
          <b-col col cols="9">
            {{ $t('admin.settings.content.email.introduction') }}
          </b-col>
        </b-row>
        <BlankLine :number-of-rows="1"/>
        <b-row>
          <b-col class="col-9">
            <label for="email"> {{ $t('admin.settings.content.common.email') }} </label>
            <b-form-input id="email" v-model="email" type="email" @input="handleInput"/>
            <p v-if="!isEmailValid() && emailEntered" style="color: red;">
              {{ $t('admin.settings.content.email.validity') }}
            </p>
          </b-col>
        </b-row>
      </div>
      <div class="setting-fixed-bottom">
        <b-button
            :disabled="!isEmailValid()"
            block
            v-bind:class="{'enable': isEmailValid(), 'disable':!isEmailValid()}"
            variant="primary"
            @click="handleOnClick">
          {{ $t('admin.settings.content.common.save') }}
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import ApiUtils from '@/utils/ApiUtils';
import BlankLine from "@/components/Blank/BlankLine.vue";

export default {
    name: 'PasswordSettings',
    components: {BlankLine},
    mixins: [ApiUtils],
    props: {
        makeToast: {
            type: Function,
            default: () => []
        }
    },
    data() {
        return {
            email: '',
            emailEntered: false,
        };
    },
    async mounted() {
        const userRole = await this.$auth.getUserRoles();
        this.userId = userRole.data.sub;
    },
    computed: {
        validateEmail: function () {
            const {user: {email}} = this.$auth;
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const isWellFormed = regex.test(this.email);
            if (this.$auth.isOwner()) return isWellFormed;
            if (this.$auth.isAdmin()) return isWellFormed && this.email === email;
            return false;
        }
    },
    methods: {
        async handleOnClick() {
            try {
                await this.setHeader();
                await this.$api.updateUserPassword({clientId: '', email: this.email});
                this.makeToast(true, this.$t('admin.settings.common.user_update.successful'));
            } catch (e) {
                this.makeToast(false, this.$t('admin.settings.common.user_update.failed'));
            }
        },
        isEmailValid() {
            return this.validateEmail;
        },
        handleInput() {
            this.emailEntered = this.email !== '';
        }
    }
};
</script>
