/**
 * ErrorMessage represents the errors that you should dispay on the screen.
 * It contains two user-friendly fields that can be used in any UI component.
 */
export default class ErrorMessage {
    constructor(title, details) {
        this.title = title;
        this.details = details;
    }
}
