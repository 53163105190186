/*
        WAL_MANUAL, // Payout Is triggered by the user from mobile app
        WAL_AUTO, // Payout is triggered automatically in case they PaymentWal.paydate is expired
        UNKNOWN // Unknown method
*/

const payoutMode = {
    WAL_MANUAL: {
        name: "Manual Payout",
        type: "WAL_MANUAL"
    },
    WAL_AUTO: {
        name: "Automatic Payout",
        type: "WAL_AUTO"
    },
    UNKNOWN: {
        name: "Please select a Payout Mode",
        type: null
    },
};

export default function getPayoutMode(type) {
    if (payoutMode[type]) return payoutMode[type].type;
    return payoutMode.UNKNOWN.type;
}
