import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';

import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // f
// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/styles/custom.scss';

// API Configuration
import AxiosFactory from '@/services/AxiosFactory';
import getApi from '@/services/ApiService';
import endPoints from '@/services/endpoints';

// General configuration
import App from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';

// Error handler
import ErrorHandler from '@/utils/default-error-handler';

// Auth0
import { Auth0Plugin } from './auth/authWrapper';

import i18n from './i18n';
import getVersion from '@/services/VersionService';
import './registerServiceWorker';

const domain = process.env.VUE_APP_AUTH0_DOMAIN_URL;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const audience = process.env.VUE_APP_API_AUTH0_AUDIENCE;

Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
        if (appState?.targetUrl) {
            router.push(appState.targetUrl);
        } else {
            router.push(window.location.pathname);
        }
    },
});

// API configuration
const axiosInstance = new AxiosFactory(process.env.VUE_APP_API_BASEURL).getAxiosInstance();
Vue.prototype.$api = getApi(axiosInstance, endPoints, store, router);
Vue.prototype.$version = getVersion();
Vue.prototype.$store = store;
// Bootstrap configuration
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// Error handling configuration
Vue.use(ErrorHandler);
Vue.use(VueQuillEditor);
Vue.use(store);
// Put the application in the right Mode
Vue.config.productionTip = process.env.VUE_APP_ENV !== 'production'; // Production Tip in Prod, Acc, Test
Vue.config.silent = process.env.VUE_APP_ENV === 'production'; // Silent For Prod, Acc, Test
Vue.config.devtools = process.env.VUE_APP_ENV === 'development'; // Devtools in Dev, Acc, Test
Vue.config.debug = process.env.VUE_APP_ENV === 'development'; // Debug enabled in Dev, Acc, Test

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app');
