<template>
  <b-row class="general-content">
    <b-col>
      <header>
        <p>{{ $t('admin.settings.content.general.title') }}</p>
        <span>{{ $t('admin.settings.content.general.subtitle') }}</span>
      </header>
      <div class="modal-main">
        <b-row>
          <b-col>
            <label>{{ $t('admin.settings.content.general.change_language') }}</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-select v-model="selectedLanguage" :options="options"></b-form-select>
          </b-col>
        </b-row>
      </div>
      <div class="setting-fixed-bottom">
        <b-button
            :disabled="isDisabled()"
            block
            v-bind:class="{'enable': !isDisabled(), 'disable':isDisabled()}"
            variant="primary"
            @click="handleOnClick">
          {{ $t('admin.settings.content.common.save') }}
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'GeneralSettings',
    data() {
        return {
            selectedLanguage: '',
            options: [
                {
                    value: 'en',
                    text: this.$t('admin.common.language.en')
                },
                {
                    value: 'NL_nl',
                    text: this.$t('admin.common.language.nl_NL')
                },
            ]
        };
    },
    mounted() {
        this.selectedLanguage = this.$i18n.locale;
    },
    methods: {
        handleOnClick() {
            this.switchLocale(this.selectedLanguage);
        },
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                this.$store.commit('setLocale', locale);
            }
        },
        isDisabled() {
            return this.selectedLanguage === '';
        }
    }
};
</script>
