const versionNumber = process.env.VUE_APP_WEB_APP_VERSION;

class VersionService {
    fetchVersion() {
        return versionNumber;
    }
}

let version;
const getVersion = () => {
    version = version || new VersionService();
    return version;
};

export default getVersion;
