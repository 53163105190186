export const generateFileName = (type, format) => {
    return `export_${type.toLowerCase()}_${new Date()
        .toLocaleString('nl-NL')
        .replaceAll(' ', '-')
        .replaceAll(':', '-')}.${format}`;
};
export const downloadFile = (content, fileName, mimeType) => {
    const a = document.createElement('a');
    const mimeTypeDefine = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {type: mimeTypeDefine}), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {type: mimeTypeDefine}));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
};