import submittedImage from '@/assets/images/icon-verified-submitted.png';
import paidImage from '@/assets/images/icon-verified-paid.png';
import retryImage from '@/assets/images/icon-error-status-retrying.png';
import errorImage from '@/assets/images/icon-error.png';
import infoImage from '@/assets/images/icon-info-square.png';
import colors from '@/utils/ColorsConts';

/***
 *
 * ACTIVE, // Onboarded and active
 * NEED_APPROVAL, // Threshold # User Creation Breach
 * PAYMENT_ON_HOLD, // Threshold # IBAN Change Breach
 * BLOCKED, // Example: Suspected - Fraud Case
 * OFFBOARDING_INITIATED, // Offboarding Request Platform Step 1
 * OFFBOARDING_WALLET_EMPTY, // Offboarding Request Platform Step 2
 * OFFBOARDED, // Offboarding Request Platform Step 3
 * CLOSED, // Store data fault and Mask in Payday
 *
 * */
class WStatus {
    constructor(symbol, name, img, color, className) {
        this.symbol = symbol;
        this.name = name;
        this.img = img;
        this.color = color;
        this.className = className;
        this.code = symbol;
    }
    getStatus = (status) => {
        return WorkerStatus[status];
    }
}

const WorkerStatus = {
    ACTIVE: new WStatus(
        'ACTIVE',
        'admin.worker-status.active',
        paidImage,
        colors.green,
        'payment-status-paid-container'
    ),
    BLOCKED : new WStatus(
        'BLOCKED',
        'admin.worker-status.blocked',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    ),
    CLOSED : new WStatus(
        'CLOSED',
        'admin.worker-status.closed',
        infoImage,
        colors.lightBlue,
        'payment-status-submitted-container'
    ),
    OFFBOARDED : new WStatus(
        'OFFBOARDED',
        'admin.worker-status.offboarded',
        retryImage,
        colors.lightGreen,
        'payment-status-toconfirm-container'
    ),
    OFFBOARDING_INITIATED : new WStatus(
        'OFFBOARDING_INITIATED',
        'admin.worker-status.offboard-initialized',
        submittedImage,
        colors.lightBlue,
        'payment-status-toconfirm-container'
    ),
    OFFBOARDING_WALLET_EMPTY : new WStatus(
        'OFFBOARDING_WALLET_EMPTY',
        'admin.worker-status.offboard-wallet-empty',
        submittedImage,
        colors.lightGreen,
        'payment-status-toconfirm-container'
    ),
    NEED_APPROVAL : new WStatus(
        'NEED_APPROVAL',
        'admin.worker-status.need-approval',
        retryImage,
        colors.lightGrey,
        'payment-status-toconfirm-container'
    ),
    PAYMENT_ON_HOLD : new WStatus(
        'PAYMENT_ON_HOLD',
        'admin.worker-status.payment-on-hold',
        retryImage,
        colors.lightGrey,
        'payment-status-toconfirm-container'
    ),
    USER_BASED_THRESHOLD_REACHED: new WStatus(
        'USER_BASED_THRESHOLD_REACHED',
        'admin.worker-status.threshold-reached',
        infoImage,
        colors.lightBlue,
        'payment-status-submitted-container'
    ),
    UNKNOWN: new WStatus(
        'UNKNOWN',
        'admin.invoice.status.unknown',
        errorImage,
        colors.red,
        'payment-status-failed-container'
    ),
};

export function getWorkerStatus(status){
    if (WorkerStatus[status] !== undefined) return WorkerStatus[status];
    else return WorkerStatus["UNKNOWN"];
}
export default WorkerStatus;
