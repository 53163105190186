const cropperProperties = {
    aspectRatio: 1,
    closeOnSave: true,
    cropArea: 'circle',
    croppedHeight: 150,
    croppedWidth: 150,
    cropperHeight: 300,
    dropareaMessage: '',
    frameLineDash: [5, 3],
    handleSize: 10,
    layoutBreakpoint: 650,
    maxCropperHeight: 300,
    maxFileSize: 8000000,
    overlayFill: 'rgba(0, 0, 0, 0.5)',
    previewOnDrag: true,
    previewQuality: 1,
    resultQuality: 1,
    resultMimeType: 'image/jpeg',
    selectButtonLabel: 'Select Files',
    showPreview: false,
    skin: 'fpp',
    uploadData: {},
    uploadTo: false
};
const getProperties = () => cropperProperties;
export default getProperties;
