import PaymentStatus from '@/utils/PaymentStatus';
import PaymentType, {getPaymentType} from '@/utils/PaymentType';

/**
 * A collection of possible internal statuses for a two level approval process.
 * @enum {string}
 */
export const TwoLevelApprovalStatus = {
    NEED_APPROVAL: 'NEED_APPROVAL',
    APPROVED_BY_PAYDAY: 'APPROVED_BY_PAYDAY',
    APPROVED_BY_PLATFORM: 'APPROVED_BY_PLATFORM',
    APPROVED: 'APPROVED',
    NO_ACTION: 'NO_ACTION',
    NOT_APPROVED: 'NOT_APPROVED',
    REFUSED_BY_PAYDAY: 'REFUSED_BY_PAYDAY',
    REFUSED_BY_PLATFORM: 'REFUSED_BY_PLATFORM'
};

const PAYDAY = 'ROLE_OWNER';
/**
 * Determines the two level approval status for a given transaction.
 * @param {Object} transactions - An object containing information about the transaction.
 * @param {string} transactions.status - The current status of the transaction.
 * @param {string} transactions.approver - The user-type that need to approve/refuse the transaction.
 * @param {string} transactions.approvedBy - The user-type that approved part of the process for the transaction.
 * @param {string} transactions.intermediateStatus - The intermediate status of the transaction.
 * @param {string} transactions.paymentType - The payment type of the transaction.
 * @returns {string} - The two level approval status for the transaction.
 */
export function getTwoLevelApprovalStatus(transactions) {
    const {status, approver, approvedBy, intermediateStatus, paymentType} = transactions;
    const pt = getPaymentType(paymentType);
    if (pt.symbol === PaymentType.PAYOUTFEE.symbol) {
        return status;
    }
    if (pt.symbol === PaymentType.WALCADJ.symbol
        || pt.symbol === PaymentType.PAYC_ADJHOUR.symbol
        || pt.symbol === PaymentType.PAYC_ADJNET.symbol) {
        return TwoLevelApprovalStatus.NO_ACTION;
    }
    if (status === PaymentStatus.TOCONFIRM.symbol) {
        if (approvedBy === null && approver === null && intermediateStatus === null) {
            // special case This is not supposed to happen
            return TwoLevelApprovalStatus.NEED_APPROVAL;
        }
        if (intermediateStatus === PaymentStatus.REFUSED.symbol) {
            // 1 Level refusal has been executed by one of the two actors
            if (approvedBy === PAYDAY) {
                return TwoLevelApprovalStatus.REFUSED_BY_PAYDAY;
            } else {
                return TwoLevelApprovalStatus.REFUSED_BY_PLATFORM;
            }
        }
        if (approvedBy === null) {
            return TwoLevelApprovalStatus.NEED_APPROVAL;
        } else {
            // 1 Level approval has been executed by one of the two actors
            if (approvedBy === PAYDAY) {
                return TwoLevelApprovalStatus.APPROVED_BY_PAYDAY;
            } else {
                return TwoLevelApprovalStatus.APPROVED_BY_PLATFORM;
            }

        }
    }
    if (status === PaymentStatus.PAYC_STORED.symbol || status === PaymentStatus.PAY_STORED.symbol) {
        // 1 or 2 level approval has been approved
        return TwoLevelApprovalStatus.APPROVED;
    }
    return TwoLevelApprovalStatus.NOT_APPROVED;
}
